import { createSlice } from "@reduxjs/toolkit";
export const ComponentFunctionSlice = createSlice({
  name: "ComponentFunctionSlice",
  initialState: {
    courierEdit: false,
    ManifestPageSize: "_auto",
    layoutType: null,
    filterFormData: {},
  },
  reducers: {
    setCourierEdit: (state, action) => {
      state.courierEdit = action.payload;
    },
    setManifestPageSize: (state, action) => {
      state.ManifestPageSize = action.payload;
    },
    setLayoutType: (state, action) => {
      state.layoutType = action.payload;
    },
    setFilterFormData: (state, action) => {
      state.filterFormData = action.payload;
    },
  },
});
export const {
  setCourierEdit,
  setManifestPageSize,
  setLayoutType,
  setFilterFormData,
} = ComponentFunctionSlice.actions;

export const getCourierEdit = (state) =>
  state.ComponentFunctionSlice.courierEdit;
export const getManifestPageSize = (state) =>
  state.ComponentFunctionSlice.ManifestPageSize;
export const getLayoutType = (state) => state.ComponentFunctionSlice.layoutType;
export const getFilterFormData = (state) =>
  state.ComponentFunctionSlice.filterFormData;
export default ComponentFunctionSlice.reducer;
