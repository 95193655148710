export const CustomerType = {
  NORMAL: "Normal",
  BUSINESS: "Business",
  FRANCHISE: "Franchise",
  Other: "Other",
};

export const CustomerNeed = {
  BRAND: "brand",
  SOCIAL: "social",
  DOCUMENTS: "documents",
  GIFTS: "gifts",
  TRADERS: "trader",
};

export const CustomerSellPoint = {
  MARKET_PLACE: "marketplace",
  WEBSITE: "website",
  SOCIAL: "social",
  RETAILS: "retails",
};

export const ProductCategory = {
  CLOTHS: "cloths",
  ELECTRONICS: "electronics",
  ELECTRONIC_PARTS: "electronicParts",
  PACKAGED_FOOD: "packagedFood",
  HOME_KITCHEN: "homeKitchen",
  HEALTH_BEAUTY: "healthBeauty",
  SPORTS_FITNESS: "sportsFitness",
  KIDS: "kids",
  INDUSTRIAL: "industrialParts",
  OTHER: "other",
};

export const ProductType = [
  {
    value: "Documents",
    label: "Documents",
  },
  {
    value: "Books",
    label: "Books",
  },
  {
    value: "Cloths",
    label: "Cloths",
  },
  {
    value: "Gifts",
    label: "Gifts",
  },
  {
    value: "Electronics",
    label: "Electronics",
  },
  {
    value: "Food",
    label: "Food",
  },

  {
    value: "Medicines",
    label: "Medicines",
  },
  {
    value: "Household",
    label: "Household",
  },
  {
    value: "Other",
    label: "Other",
  },
];
export const ShipmentType = [
  {
    value: "isB2bAccount",
    label: "B2B shipment ( Business to Business )",
  },
  {
    value: "isB2cAccount",
    label: "B2C shipment ( Business to Customer )",
  },
  { value: "isHyperLocal", label: "Hyperlocal shipment" },
  {
    value: "isInternationalShipment",
    label: "International shipment",
  },
];
export const PackagingType = [
  {
    value: "Envelope",
    label: "Envelope",
  },
  {
    value: "Parcel Bag",
    label: "Parcel Bag",
  },
  {
    value: "Parcel Box",
    label: "Parcel Box",
  },
  {
    value: "Not Packed",
    label: "Not Packed",
  },
];
export const ParcelQty = [
  {
    value: "1 Parcel",
    label: "1 Parcel",
  },
  {
    value: "2 Parcel",
    label: "2 Parcel",
  },
  {
    value: "3 Parcel",
    label: "3 Parcel",
  },
  {
    value: "4 Parcel",
    label: "4 Parcel",
  },
  {
    value: "5 Parcel",
    label: "5 Parcel",
  },
  {
    value: "6 Parcel",
    label: "6 Parcel",
  },
  {
    value: "7 Parcel",
    label: "7 Parcel",
  },
  {
    value: "8 Parcel",
    label: "8 Parcel",
  },
  {
    value: "9 Parcel",
    label: "9 Parcel",
  },
  {
    value: "10 Parcel",
    label: "10 Parcel",
  },
  {
    value: "Bulk Parcel",
    label: "Bulk Parcel",
  },
];

export const ServiceProvider = {
  XPRESSBEES: [
    {
      label: "SURFACE",
      value: "Surface",
    },
  ],

  DELHIVERY: [
    {
      label: "EXPRESS",
      value: "Express",
    },
    {
      label: "SURFACE",
      value: "Surface",
    },
  ],

  SHADOWFAX: [
    {
      label: "SURFACE",
      value: "Surface",
    },
  ],
};

export const ServiceProviderList = [
  {
    name: "XPRESSBEES",
    value: "XBS",
    service: [
      {
        label: "EXPRESS",
        value: "EXP",
      },
      {
        label: "SURFACE",
        value: "SF",
      },
    ],
  },
  {
    name: "DELHIVERY",
    value: "DLV",
    service: [
      {
        label: "EXPRESS",
        value: "EXP",
      },
      {
        label: "SURFACE",
        value: "SF",
      },
    ],
  },
  {
    name: "SHADOWFAX",
    value: "SFX",
    service: [
      {
        label: "SURFACE",
        value: "SF",
      },
    ],
  },
  {
    name: "MARUTI",
    value: "SMC",
    service: [
      {
        label: "EXPRESS",
        value: "EXP",
      },
      {
        label: "SURFACE",
        value: "SF",
      },
    ],
  },
  {
    name: "EKART",
    value: "EKT",
    service: [
      {
        label: "EXPRESS",
        value: "EXP",
      },
      {
        label: "SURFACE",
        value: "SF",
      },
    ],
  },
  {
    name: "ECOM",
    value: "ECM",
    service: [
      {
        label: "EXPRESS",
        value: "EXP",
      },
      {
        label: "SURFACE",
        value: "SF",
      },
    ],
  },
];

export const SERVICE_PROVIDERS = {
  DLV: {
    name: "delhivery",
    value: "DLV",
  },
  SFX: {
    name: "shadowfax",
    value: "SFX",
  },
  XBS: {
    name: "xpressbees",
    value: "XBS",
  },
  SMC: {
    name: "maruti",
    value: "SMC",
  },
  EKT: {
    name: "ekart",
    value: "EKT",
  },
  ECM: {
    name: "ecom",
    value: "ECM",
  },
};

export const SERVICEMODE = {
  FW: "Forward",
  RV: "Reverse",
  RT: "Return",
};

export const PGPAYMENTMODE = {
  CREDIT: "CC",
  DEBIT: "DC",
  EBT: "NB",
  UPI: "UPI",
  WALLET: "WALLET",
};

export const SERVICETYPES = {
  SURFACE: "SURFACE",
  EXPRESS: "EXPRESS",
};

export const QUIXPAYMENTMODE = {
  CREDIT: "credit",
  DEBIT: "debit",
  EBT: "ebt",
  CASH: "cash",
  PCHECK: "pcheck",
  ECHECK: "echeck",
  UPI: "upi",
  QRCODE: "qrcode",
  MOBILEWALLET: "mobileWallet",
  QUIXWALLET: "quixWallet",
  QUIXCASHBACK: "quixCashback",
};
export const CompanyType = [
  {
    value: "pts",
    label: "Partnership",
  },
  {
    value: "llp",
    label: "Limited Liability Partnership",
  },
  {
    value: "pvt",
    label: "Private Limited Company",
  },
  {
    value: "plc",
    label: "Public Limited Company",
  },
  {
    value: "opc",
    label: "One Person Company",
  },
  {
    value: "sec",
    label: "Section 8 Company",
  },

  {
    value: "ngo",
    label: "Non-Government Organization",
  },
  {
    value: "prop",
    label: "Proprietorship",
  },
  {
    value: "na",
    label: "Not Applicable",
  },
];
export const defautShipmentPartnerValue = [
  {
    name: "delhivery",
    value: "DLV",
  },
  {
    name: "shadowfax",
    value: "SFX",
  },
  {
    name: "xpressbees",
    value: "XBS",
  },
  {
    name: "maruti",
    value: "SMC",
  },
  {
    name: "ekart",
    value: "EKT",
  },
  {
    name: "ecom",
    value: "ECM",
  },
  {
    name: "EXPRESS",
    value: "EXP",
  },
  {
    name: "SURFACE",
    value: "SF",
  },
  {
    name: "The Cheapest Courier First",
    value: "cheapest",
  },
  {
    name: "Quixgo Priority",
    value: "quixgo",
  },
  {
    name: "Fastest Service",
    value: "fastest",
  },
];
export const manifestSettingData = [
  {
    name: "quixgoLogo",
    title: "Hide Quixgo logo",
    description: "Hide Quixgo Logo",
  },
  {
    name: "customerLogo",
    title: "Hide Display Logo",
    description: "Hide Display Logo",
  },
  {
    name: "invoiceValue",
    title: "Hide Invoice Value",
    description: "Hide Invoice Value",
  },
  {
    name: "returnAddress",
    title: "Hide Return Address",
    description: "Hide Return Address",
  },
  {
    name: "sellerContactDetails",
    title: "Hide Seller Contact Details",
    description: "Hide Seller Contact Details",
  },
  {
    name: "products",
    title: "Hide Products",
    description: "Hide Products",
  },
  {
    name: "weight",
    title: "Hide Weight",
    description: "Hide Weight",
  },
  {
    name: "consigneeMobile",
    title: "Hide consignee Mobile",
    description: "Hide consignee Mobile",
  },
];
export const Zone = [
  { label: "Within City", value: "city" },
  { label: "Metro to Metro", value: "metro" },
  { label: "Within Zone", value: "zone" },
  { label: "Rest of India", value: "india" },
  { label: "Extended Location", value: "special" },
];
