import React from "react";
import { Heading, VStack } from "@chakra-ui/react";
import CustomerInfoContainer from "./CustomerInfoContainer";
import { getIsCustomerTypeLoading } from "../../redux/slices/CustomerDetailsSlice";
import { useSelector } from "react-redux";
import ContentLoader from "../../components/Loader/ContentLoader";
const CustomerInfoModel = () => {
  const isCustomerLoading = useSelector(getIsCustomerTypeLoading);
  return isCustomerLoading ? (
    <ContentLoader />
  ) : (
    <VStack
      px={[5]}
      py={[5, 7]}
      whiteSpace={"wrap"}
      spacing={[5, 7]}
      h={"100%"}
    >
      <Heading as="h1" textAlign={"center"} size={"lg"} color="blue.500">
        Welcome to Quixgo. Please answer a few questions for serving you in the
        best way.
      </Heading>
      <CustomerInfoContainer />
    </VStack>
  );
};

export default CustomerInfoModel;
