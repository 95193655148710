/* eslint-disable no-unused-vars */
import { ColorModeScript } from "@chakra-ui/react";
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { AuthProvider } from "./context/AuthProvider";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const rootElement = document.getElementById("root");
let AppComponent = (
  <>
    <ColorModeScript />
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </>
);

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, AppComponent);
} else {
  const root = createRoot(rootElement);
  root.render(AppComponent);
}

serviceWorker.unregister();
