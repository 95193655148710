import React from "react";
import { VStack, RadioGroup, Radio, Heading } from "@chakra-ui/react";
import { CustomerType } from "../../../utils/utilityConstants";
const CustomerTypeInfo = ({ onChange, value }) => {
  const handleOnChange = (value) => {
    onChange("customerType", value);
  };
  return (
    <>
      <Heading as="h2" size={"lg"} color="blackAlpha.800" mb={4}>
        What is the purpose to join us?
      </Heading>
      <RadioGroup onChange={handleOnChange} value={value}>
        <VStack spacing={4} align="stretch">
          <Radio value={CustomerType.BUSINESS}>Business Use</Radio>
          <Radio value={CustomerType.NORMAL}>
            Individual sending a few personal shipment
          </Radio>
          <Radio value={CustomerType.Other}>Others</Radio>
        </VStack>
      </RadioGroup>
    </>
  );
};

export default CustomerTypeInfo;
