import React from "react";
import {
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  HStack,
  Flex,
  Select,
  IconButton,
  Tag,
  TagLeftIcon,
  TagLabel,
} from "@chakra-ui/react";
import { MdArrowDropDown, MdDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { useFieldArray } from "react-hook-form";

const DimensionInput = ({ register, control, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "volume",
  });

  return (
    <>
      <>
        <Flex
          flexDir={"column"}
          gap={4}
          border={"1px solid"}
          borderColor={"blackAlpha.200"}
          p={5}
          rounded={"md"}
        >
          <>
            <FormControl isInvalid={errors.unit}>
              <Select
                w={150}
                {...register("unit", {
                  required: "This is required",
                })}
                icon={<MdArrowDropDown />}
                textTransform={"capitalize"}
                fontWeight={"bold"}
                color={"#718096"}
              >
                {["cm", "feet", "inch"].map((mode) => (
                  <option
                    style={{
                      fontWeight: "bold",
                      color: "#718096",
                    }}
                    key={mode}
                    value={mode}
                  >
                    {mode}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.unit && errors.unit.message}
              </FormErrorMessage>
            </FormControl>
          </>
          {fields?.map((field, index) => (
            <Flex key={field.id} gap={4} direction={["column", "row"]}>
              <FormControl isInvalid={errors.volume?.[index]?.Qty}>
                <Input
                  type="number"
                  placeholder="Qty"
                  _placeholder={{
                    fontWeight: "bold",
                  }}
                  {...register(`volume.${index}.Qty`, {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.volume?.[index]?.Qty?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.volume?.[index]?.height}>
                <Input
                  type="number"
                  placeholder="Height"
                  _placeholder={{
                    fontWeight: "bold",
                  }}
                  {...register(`volume.${index}.height`, {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.volume?.[index]?.height?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.volume?.[index]?.length}>
                <Input
                  type="number"
                  placeholder="Length"
                  _placeholder={{
                    fontWeight: "bold",
                  }}
                  {...register(`volume.${index}.length`, {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.volume?.[index]?.length?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.volume?.[index]?.width}>
                <Input
                  type="number"
                  placeholder="Width"
                  _placeholder={{
                    fontWeight: "bold",
                  }}
                  {...register(`volume.${index}.width`, {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.volume?.[index]?.width?.message}
                </FormErrorMessage>
              </FormControl>
              {fields?.length !== 1 && (
                <IconButton
                  icon={<MdDelete />}
                  onClick={() => remove(index)}
                  colorScheme="red"
                />
              )}
            </Flex>
          ))}
          <Tag
            mx={"auto"}
            colorScheme="blackAlpha"
            px={5}
            py={2}
            letterSpacing={1.5}
            fontSize={16}
            fontWeight={"bold"}
            cursor={"pointer"}
            border={"1px solid transparent"}
            _hover={{ border: "1px solid gray" }}
            onClick={() =>
              append({ Qty: "", height: "", length: "", width: "" })
            }
          >
            <TagLeftIcon color={"blackAlpha.600"} boxSize="12px" as={FaPlus} />
            <TagLabel color={"blackAlpha.600"}>Add More</TagLabel>
          </Tag>
        </Flex>
      </>
    </>
  );
};

export default DimensionInput;
