import React, { useRef } from "react";
import { Button } from "@chakra-ui/react";
import { MdImage } from "react-icons/md";

const UploadSelfie = ({ onChange }) => {
  const inputRef = useRef(null);
  return (
    <div>
      <input
        ref={inputRef}
        hidden
        type="file"
        name="file"
        onChange={onChange}
      />
      <Button
        leftIcon={<MdImage />}
        variant="outline"
        colorScheme={"blue"}
        onClick={() => {
          inputRef.current.click();
        }}
        w={220}
      >
        Upload Selfie
      </Button>
    </div>
  );
};

export default UploadSelfie;
