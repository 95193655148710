import { Button } from "@chakra-ui/button";
import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/input";
import { Box, Heading, useClipboard, Icon } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getCustomerInfo } from "../redux/slices/CustomerDetailsSlice";
import { generateRandomString } from "../utils/utilsFunction";
const SetupUrlButton = ({ channelName }) => {
  const customerInfo = useSelector(getCustomerInfo);
  const [inputText, setInputText] = useState("");
  //---------------------------------
  function customBase64Encode(input) {
    let encoded = btoa(btoa(input));
    return encoded.replace(/\//g, "@");
  }
  const randomString = generateRandomString();
  //---------------------------------
  useEffect(() => {
    const encCode = customBase64Encode(
      `${customerInfo?.customerId}#${randomString}`
    );
    setInputText(
      `${process.env.REACT_APP_BASEURL}/channel/${channelName}/${encCode}`
    );
  }, [customerInfo.customerId, channelName]);

  const { hasCopied, onCopy } = useClipboard(inputText);
  return (
    <>
      <Box w={["80vw", "80vw", "40vw"]} ml={[0, 10]} mt={[4]}>
        <Heading color={"blackAlpha.800"}>Connect with Link</Heading>
        <InputGroup size="md" pt={2}>
          <InputLeftAddon children={`${channelName}`} bg="gray.100" />
          <Input
            value={inputText}
            type="text"
            placeholder="https:/example/1234"
            px={3}
            py={2}
            borderColor="gray.300"
            borderRadius="md"
            _hover={{ borderColor: "gray.400" }}
            _focus={{ borderColor: "blue.400" }}
            autoFocus
            isDisabled={true}
            _disabled={{ opacity: "0.8" }}
            color={"black"}
            cursor={"not-allowed"}
          />
          <Button
            isLoading={hasCopied}
            p={4}
            colorScheme="blue"
            onClick={onCopy}
          >
            <Icon as={FaRegCopy} />
          </Button>
        </InputGroup>
      </Box>
    </>
  );
};

export default SetupUrlButton;
