import { createSlice } from "@reduxjs/toolkit";
export const CustomerDetailsSlice = createSlice({
  name: "CustomerDetailsSlice",
  initialState: {
    customerInfo: "",
    idTypes: [],
    isCustomerInfoLoading: false,
    isBankDetailsLoading: false,
    bankDetails: [],
    customerInfoFormData: {},
    addressInfo: {},
    kycDocs: {},
    isCustomerTypeLoading: false,
    customerType: null,
  },
  reducers: {
    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    setIdList: (state, action) => {
      state.idTypes = action.payload;
    },
    setIsCustomerInfoLoading: (state, action) => {
      state.isCustomerInfoLoading = action.payload;
    },
    setBankDetails: (state, action) => {
      state.bankDetails = action.payload;
    },
    setIsBankDetailsLoading: (state, action) => {
      state.isBankDetailsLoading = action.payload;
    },
    setCustomerInfoFormData: (state, action) => {
      state.customerInfoFormData = action.payload;
    },
    setAddressInfo: (state, action) => {
      state.addressInfo = action.payload;
    },
    setKycDocs: (state, action) => {
      state.kycDocs = action.payload;
    },
    setIsCustomerTypeLoading: (state, action) => {
      state.isCustomerTypeLoading = action.payload;
    },
    setCustomerType: (state, action) => {
      state.customerType = action.payload;
    },
  },
});
export const {
  setCustomerInfo,
  setIdList,
  setIsCustomerInfoLoading,
  setBankDetails,
  setIsBankDetailsLoading,
  setCustomerInfoFormData,
  setAddressInfo,
  setKycDocs,
  setIsCustomerTypeLoading,
  setCustomerType,
} = CustomerDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getCustomerInfo = (state) =>
  state.CustomerDetailsSlice.customerInfo;
export const getIdList = (state) => state.CustomerDetailsSlice.idTypes;
export const getIsCustomerInfoLoading = (state) =>
  state.CustomerDetailsSlice.isCustomerInfoLoading;
export const getBankDetails = (state) => state.CustomerDetailsSlice.bankDetails;
export const getIsBankDetailsLoading = (state) =>
  state.CustomerDetailsSlice.isBankDetailsLoading;
export const getIsCustomerTypeLoading = (state) =>
  state.CustomerDetailsSlice.isCustomerTypeLoading;
export const getCustomerType = (state) =>
  state.CustomerDetailsSlice.customerType;
export const getCustomerInfoFormData = (state) =>
  state.CustomerDetailsSlice.customerInfoFormData;
export const getAddressInfo = (state) => state.CustomerDetailsSlice.addressInfo;
export const getKycDocs = (state) => state.CustomerDetailsSlice.kycDocs;
///we can also write thunks by hand , which may contain both sync and async logic

export default CustomerDetailsSlice.reducer;
