import React from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
} from "@chakra-ui/react";
import ShopifySetupPage from "./ShopifySetupPage";
import ShopifyFulfilment from "./ShopifyFulfilment";
const index = () => {
  return (
    <Tabs isLazy={true} colorScheme="blue">
      <TabList flexFlow={"wrap"} pl={5} pt={2}>
        <Tab>Setup</Tab>
        <Tab>Fulfilment</Tab>
        <Tab>Cancel</Tab>
        <Tab>Status</Tab>
      </TabList>

      <TabPanels className="tabPanel" overflow="auto">
        <TabPanel>
          <ShopifySetupPage />
        </TabPanel>
        <TabPanel>
          <ShopifyFulfilment channelName={"shopify"} />
        </TabPanel>
        <TabPanel>
          <Flex p={5} fontSize={"2xl"} color={"black"} fontWeight={"bold"}>
            Page Under Development
          </Flex>
        </TabPanel>
        <TabPanel>
          <Flex p={5} fontSize={"2xl"} color={"black"} fontWeight={"bold"}>
            Page Under Development
          </Flex>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default index;
