import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Flex,
  Spacer,
  Center,
  Button,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import CustomerTypeInfo from "./CustomerForm/CustomerTypeInfo";
import CustomerJobForm from "./CustomerForm/CustomerJobsForm";
import ShipmentCountForm from "./CustomerForm/ShipmentCountForm";
import CustomerSellMedia from "./CustomerForm/CustomerSellMedia";
import CompanyDetailsForm from "./CustomerForm/CompanyDetailsForm";
import NormalCustomerDetailsForm from "./CustomerForm/NormalCustomerDetailsForm";
import KycDocumentForm from "./CustomerForm/KycDocumentForm";
import { setCustomerInfoFormData } from "../../redux/slices/CustomerDetailsSlice";
import { CustomerType } from "../../utils/utilityConstants";

const CustomerInfoContainer = ({ onClose }) => {
  const [customerFormData, setCustomerFormData] = useState(
    JSON.parse(localStorage.getItem("customerFormData"))
  );
  const dispatch = useDispatch();
  const [formNumber, setFormNumber] = useState(1);
  const onChange = (field, value) => {
    const customerData = { ...customerFormData };
    customerData[field] = value;
    dispatch(setCustomerInfoFormData(customerData));
    setCustomerFormData(customerData);
    localStorage.setItem("customerFormData", JSON.stringify(customerData));
  };
  useEffect(() => {
    const storedValue = JSON.parse(localStorage.getItem("customerFormData"));
    if (storedValue) {
      setCustomerFormData(storedValue);
    }
  }, []);
  const handleNext = () => {
    const increment =
      (customerFormData?.customerType === CustomerType.NORMAL ||
        customerFormData?.customerType === CustomerType.Other) &&
      (formNumber === 1 || formNumber === 3)
        ? 2
        : 1;
    setFormNumber(formNumber + increment);
  };
  const handlePrev = () => {
    const decrement =
      (customerFormData?.customerType === CustomerType.NORMAL ||
        customerFormData?.customerType === CustomerType.Other) &&
      (formNumber === 3 || formNumber === 5)
        ? 2
        : 1;
    setFormNumber(formNumber - decrement);
  };
  const isDisableNext = () => {
    if (formNumber === 1 && customerFormData?.customerType) {
      return false;
    } else if (formNumber === 2 && customerFormData?.job) {
      return false;
    } else if (formNumber === 3 && customerFormData?.count) {
      return false;
    } else if (
      formNumber === 4 &&
      customerFormData?.media &&
      Object.values(customerFormData?.media).length === 3
    ) {
      return false;
    } else if (
      formNumber === 5 &&
      customerFormData?.companyDetails &&
      Object.keys(customerFormData?.companyDetails).length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <Center>
      <Card background={"white"}>
        <CardBody>
          {formNumber === 1 && (
            <CustomerTypeInfo
              onChange={onChange}
              value={customerFormData?.customerType}
            />
          )}
          {formNumber === 2 &&
            customerFormData?.customerType !== CustomerType.NORMAL && (
              <CustomerJobForm
                onChange={onChange}
                value={customerFormData?.job}
              />
            )}
          {formNumber === 3 && (
            <ShipmentCountForm
              onChange={onChange}
              value={customerFormData?.count}
            />
          )}
          {formNumber === 4 &&
            customerFormData?.customerType !== CustomerType.NORMAL && (
              <CustomerSellMedia
                onChange={onChange}
                value={customerFormData?.media ? customerFormData?.media : {}}
              />
            )}
          {formNumber === 5 &&
            (customerFormData?.customerType === CustomerType.NORMAL ||
            customerFormData?.customerType === CustomerType.Other ? (
              <NormalCustomerDetailsForm
                onChange={onChange}
                value={
                  customerFormData?.normalCustomerDetails
                    ? customerFormData?.normalCustomerDetails
                    : {}
                }
                onClose={onClose}
                next={handleNext}
              />
            ) : (
              <CompanyDetailsForm
                onChange={onChange}
                value={
                  customerFormData?.companyDetails
                    ? customerFormData?.companyDetails
                    : {}
                }
                onClose={onClose}
                next={handleNext}
              />
            ))}
          {formNumber === 6 && (
            <KycDocumentForm
              onChange={onChange}
              value={customerFormData?.kyc ? customerFormData?.kyc : {}}
              onClose={onClose}
              data={customerFormData}
            />
          )}
        </CardBody>
        <CardFooter>
          <Flex w="100%">
            <Button
              colorScheme={"blue"}
              visibility={formNumber === 1 ? "hidden" : "visible"}
              onClick={handlePrev}
            >
              Previous
            </Button>
            <Spacer />
            {formNumber !== 5 && (
              <Button
                colorScheme={"blue"}
                visibility={formNumber === 6 ? "hidden" : "visible"}
                onClick={handleNext}
                isDisabled={isDisableNext()}
              >
                Next
              </Button>
            )}
          </Flex>
        </CardFooter>
      </Card>
    </Center>
  );
};

export default CustomerInfoContainer;
