import React from "react";
import {
  Heading,
  Input,
  Button,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { getIsCustomerInfoLoading } from "../../../redux/slices/CustomerDetailsSlice";
import Loader from "../../../components/Loader/TableLoader";
import { CompanyType } from "../../../utils/utilityConstants";

const CompanyDetailsForm = ({ onChange, value, next, isEdit = true }) => {
  const isLoading = useSelector(getIsCustomerInfoLoading);
  const onSubmit = async (values, e) => {
    e.preventDefault();
    const companyInfoParams = {
      companyName: values.companyName,
      brandName: values.brandName,
      companyType: values.companyType,
      companyContact: values.companyContact,
      cpName: values.cpName,
      cpMobile: values.cpMobile,
      email: values.email.trim(),
      address1: values.address1,
      address2: values.address2,
      district: values.district,
      city: values.city,
      state: values.state,
      pin: values.pin,
      website: values.website,
    };
    onChange("companyDetails", companyInfoParams);
    next();
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  return (
    <>
      <Heading as="h2" size={"lg"} color="blackAlpha.800" mb={4}>
        Tell us about your company.
      </Heading>
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {" "}
          <SimpleGrid columns={[1, 2, 3]} spacing={4}>
            <FormControl isInvalid={errors.companyType}>
              <FormLabel m={0} htmlFor="companyType">
                Select Company Type
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Controller
                name="companyType"
                control={control}
                defaultValue={value && value?.companyType}
                render={({ field }) => (
                  <Select placeholder="Select Company Type" {...field}>
                    {CompanyType.map((cType) => (
                      <option key={cType.value} value={cType.value}>
                        {cType.label}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl isInvalid={errors.companyName}>
              <FormLabel whiteSpace={"nowrap"} m={0} htmlFor="companyName">
                Registered Company Name / Seller Name
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                type="text"
                placeholder=" Company Name"
                defaultValue={value.companyName}
                {...register("companyName", {
                  required: "This is required",
                  // pattern: {
                  //   value: /^[A-Za-z][A-Za-z-\s]+$/,
                  //   message: "Please enter a valid company name",
                  // },
                })}
              />

              <FormErrorMessage>
                {errors?.companyName?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.brandName}>
              <FormLabel m={0} htmlFor="brandName">
                Brand Name
              </FormLabel>

              <Input
                type="text"
                placeholder=" Brand Name"
                defaultValue={value.brandName}
                {...register("brandName")}
              />

              <FormErrorMessage>{errors?.brandName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.companyContact} isReadOnly={!isEdit}>
              <FormLabel m={0} htmlFor="companyContact">
                Company Contact Number
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                readOnly={!isEdit}
                id="companyContact"
                placeholder=" Company Contact Number"
                defaultValue={value.companyContact}
                type="text"
                {...register("companyContact", {
                  required: "This is required",
                  pattern: {
                    value: /^[0-9]\d{9}$/,
                    message: "Wrong mobile number",
                  },
                })}
              />
              <FormErrorMessage>
                {errors?.companyContact?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.cpName} isReadOnly={!isEdit}>
              <FormLabel m={0} htmlFor="cpName">
                Contact Person Name
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="cpName"
                readOnly={!isEdit}
                placeholder="Contact Person Name"
                type="text"
                defaultValue={value.cpName}
                {...register("cpName", {
                  required: "This is required",
                  pattern: {
                    value: /^[A-Za-z][A-Za-z-\s]+$/,
                    message: "Please enter a valid name(first_name last_name) ",
                  },
                })}
              />
              <FormErrorMessage>{errors?.cpName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.cpMobile} isReadOnly={!isEdit}>
              <FormLabel m={0} htmlFor="cpMobile">
                Contact Person Mobile No.
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                readOnly={!isEdit}
                id="cpMobile"
                placeholder=" Contact Person Mobile No."
                defaultValue={value.cpMobile}
                type="text"
                {...register("cpMobile", {
                  required: "This is required",
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: "Wrong mobile number",
                  },
                })}
              />
              <FormErrorMessage>{errors?.cpMobile?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.email} isReadOnly={!isEdit}>
              <FormLabel m={0} htmlFor="email">
                Company Email ID
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                readOnly={!isEdit}
                id="email"
                placeholder="example123@gmail.com"
                defaultValue={value.email}
                type="text"
                {...register("email", {
                  required: "This is required",
                  pattern: {
                    value: /[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-\s]+$/,
                    message: "Wrong email id",
                  },
                })}
              />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <Heading as="h2" size={"lg"} color="blackAlpha.800" my={4}>
            Office Address
          </Heading>
          <SimpleGrid columns={[1, 2, 3]} spacing={2}>
            <FormControl isInvalid={errors.address1} isReadOnly={!isEdit}>
              <FormLabel m={0}>
                Address Line 1
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Input
                readOnly={!isEdit}
                id="address1"
                placeholder="Office No./Building No., Road/Street, etc."
                defaultValue={value.address1}
                type="text"
                {...register("address1", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage> {errors?.address1?.message}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel m={0}>Address Line 2</FormLabel>

              <Input
                placeholder="Area/City near by(location) etc."
                defaultValue={value.address2}
                type="text"
                {...register("address2")}
              />
            </FormControl>
            <FormControl isInvalid={errors.city} isReadOnly={!isEdit}>
              <FormLabel m={0}>
                City/Town
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Input
                readOnly={!isEdit}
                id="city"
                placeholder="City/Town"
                defaultValue={value.city}
                type="text"
                {...register("city", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage> {errors?.city?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.district} isReadOnly={!isEdit}>
              <FormLabel m={0}>
                District
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Input
                readOnly={!isEdit}
                id="district"
                placeholder="District"
                defaultValue={value.district}
                type="text"
                {...register("district", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage> {errors?.district?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.state} isReadOnly={!isEdit}>
              <FormLabel m={0}>
                State
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Input
                readOnly={!isEdit}
                id="state"
                placeholder="State"
                defaultValue={value.state}
                type="text"
                {...register("state", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.pin} isReadOnly={!isEdit}>
              <FormLabel m={0}>
                Pin
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Input
                readOnly={!isEdit}
                id="pin"
                placeholder="Pincode"
                defaultValue={value.pin}
                type="text"
                {...register("pin", {
                  required: "This is required",
                  pattern: {
                    value: /^([1-9]{1}[0-9]{5})$/,
                    message: "Wrong pincode",
                  },
                })}
              />
              <FormErrorMessage>{errors?.pin?.message}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <Heading as="h2" size={"lg"} color="blackAlpha.800" my={4}>
            {" "}
            If you have a website, please share its URL here.
            <Input
              placeholder="www.abc.com"
              size="sm"
              type="text"
              {...register("website")}
            />
          </Heading>
          <Button
            position={"absolute"}
            bottom={"21px"}
            right={"20px"}
            colorScheme="blue"
            isLoading={isLoading || isSubmitting}
            type="submit"
          >
            Next
          </Button>
        </form>
      )}
    </>
  );
};

export default CompanyDetailsForm;
