import { Box, Spinner } from "@chakra-ui/react";
import React from "react";

const ContentLoader = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Spinner size="xl" color="teal.500" />
    </Box>
  );
};

export default ContentLoader;
