const colors = {
  bgPrimary: "#38B2AC",
  bgSecondry: "#183650",
  bgthree: "#00bfa5",
  textPrimary: "#F7FAFC",
  danger: "#d32f2f",
  success: "#00e676",
  white: "#FFFFFF",
  black: "#000000",
};
export default colors;
