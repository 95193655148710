import React from "react";
import { Button, Flex, VStack, useDisclosure } from "@chakra-ui/react";
import CustomTable from "../../components/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import {
  B2c_AllShipmetnList_Filter_Component,
  TableBody,
  TableHeader,
} from "./B2cAllshipmentList_Constant";
import Pagination from "../../components/CustomTable/Pagination";
import FilterContainer from "../../components/CustomTable/FilterContainer";
import {
  getAllShipmentList,
  getSelectedShipmentInfo,
  getShipmentListLoading,
} from "../../redux/slices/BookingDetailsSlice";
import { getCustomerInfo } from "../../redux/slices/CustomerDetailsSlice";
import { AiOutlinePrinter } from "react-icons/ai";
import { setManifestPageSize } from "../../redux/slices/ComponentFunctionSlice";
import ManifestModel from "../../components/Manifest/ManifestModel";
import BookedManifestModel from "../../components/Manifest/BookedManifestModel";

const B2cAllshipmentList = () => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(getCustomerInfo);
  const AllshipmentListData = useSelector(getAllShipmentList);
  const selectedShipment = useSelector(getSelectedShipmentInfo);
  const isShipmentListLoading = useSelector(getShipmentListLoading);
  const { count, rows } = AllshipmentListData || {};
  const {
    isOpen: isManifestModelOpen,
    onOpen: handleManifestOpenModel,
    onClose: handleManifestClose,
  } = useDisclosure();
  const onManifestOpen = () => {
    const params = {
      customerId: loggedUser?.customerId,
    };
    dispatch({ type: "GET_KYC_DOCS", postData: params });
    handleManifestOpenModel();
  };
  const onManifestClose = () => {
    dispatch(setManifestPageSize("_auto"));
    handleManifestClose();
  };
  const {
    isOpen: isBookedManifestModelOpen,
    onOpen: handleBookedManifestOpenModel,
    onClose: handleBookedManifestClose,
  } = useDisclosure();
  const getData = (limit, page, filterFormData) => {
    dispatch({
      type: "GET_ALL_SHIPMENT_LIST",
      customerType: "b2c",
      postData: {
        limit: limit,
        page: page,
        filter: {
          customerId: loggedUser?.customerId,
          ...filterFormData,
        },
      },
    });
  };
  return (
    <VStack gap={2}>
      <FilterContainer
        mainComponent={B2c_AllShipmetnList_Filter_Component}
        styleprops={{
          templateColumns: [
            "auto",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
            "repeat(4, 1fr)",
            "repeat(5, 1fr)",
          ],
        }}
      />
      <Flex
        w={"100%"}
        justifyContent={["center", "flex-end"]}
        flexDir={["column", "column", "column", "row"]}
        alignItems={["center"]}
        gap={2}
      >
        <Flex
          flexDir={["column", "row"]}
          justifyContent={[
            "space-around",
            "space-around",
            "space-around",
            "flex-end",
          ]}
          alignItems={["center"]}
          gap={2}
          w={"100%"}
        >
          <Button
            leftIcon={<AiOutlinePrinter />}
            isDisabled={selectedShipment?.length === 0}
            colorScheme="blue"
            onClick={onManifestOpen}
            w={220}
            variant={"outline"}
          >
            Print Label
          </Button>
          <Button
            leftIcon={<AiOutlinePrinter />}
            isDisabled={selectedShipment?.length === 0}
            colorScheme="blue"
            onClick={handleBookedManifestOpenModel}
            w={220}
            variant={"outline"}
          >
            Print Manifest
          </Button>
        </Flex>
        <Pagination
          paginationProps={{
            count: count,
            getData: getData,
          }}
        />
      </Flex>
      <CustomTable
        tableData={{
          PropsData: rows,
          Header: TableHeader,
          Body: TableBody,
          isDataLoading: isShipmentListLoading,
        }}
        styleprops={{
          w: ["90vw"],
          h: ["90vh", "50vh", "50vh", "50vh", "60vh", "61vh"],
          overflowY: "auto",
        }}
      />
      {isManifestModelOpen && (
        <ManifestModel isOpen={isManifestModelOpen} onClose={onManifestClose} />
      )}
      {isBookedManifestModelOpen && (
        <BookedManifestModel
          isOpen={isBookedManifestModelOpen}
          onClose={handleBookedManifestClose}
        />
      )}
    </VStack>
  );
};

export default B2cAllshipmentList;
