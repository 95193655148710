import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  IconButton,
  Box,
  Flex,
  SimpleGrid,
  Button,
} from "@chakra-ui/react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { getSelectedShipmentInfo } from "../../redux/slices/BookingDetailsSlice";
import { FcPrint } from "react-icons/fc";
import ManifestSize from "./ManifestSize";
import Label from "./Label";
import { getManifestPageSize } from "../../redux/slices/ComponentFunctionSlice";
const ManifestModel = ({ onClose, isOpen, printData }) => {
  const selectedShipment = useSelector(getSelectedShipmentInfo);
  const [selectedShipmentData, setSelectedShipmentData] = useState();
  useEffect(() => {
    if (printData?.length > 0) {
      setSelectedShipmentData(printData);
    } else if (selectedShipment !== null && selectedShipment !== undefined) {
      setSelectedShipmentData(selectedShipment);
    }
  }, [printData, selectedShipment]);

  const manifestClass = useSelector(getManifestPageSize);
  const [pageSize, setPageSize] = useState();
  const [margin, setMargin] = useState();
  useEffect(() => {
    if (manifestClass === "_4_6") {
      setPageSize("4in 6in");
      setMargin("5px");
    } else if (manifestClass === "_3_5") {
      setPageSize("3in 5in");
      setMargin("5px");
    } else {
      setPageSize("A4");
      setMargin("10px");
    }
  }, [manifestClass]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page {
        size: ${pageSize};
        margin: ${margin};
        page-break-before: always;
      }
    `,
  });

  return (
    <Modal
      onClose={onClose}
      size="full"
      isOpen={isOpen}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          flexDir={["column", "column", "row"]}
          gap={[2, 5]}
          justifyContent={["center"]}
          mx={"auto"}
        >
          <Box>
            <Button
              rightIcon={<FcPrint />}
              onClick={handlePrint}
              variant="solid"
              border={"1px solid black"}
            >
              Print
            </Button>
          </Box>
          <ManifestSize />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ComponentToPrint
            ref={componentRef}
            ShipmentsToPrint={selectedShipmentData}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManifestModel;

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const { ShipmentsToPrint } = props;
  const manifestClass = useSelector(getManifestPageSize);
  return (
    <div ref={ref}>
      <SimpleGrid
        columns={
          manifestClass === "_auto" && ShipmentsToPrint?.length > 0 ? 2 : 1
        }
      >
        {ShipmentsToPrint?.map((shipment) => (
          <React.Fragment key={shipment?.shipmentId}>
            <Label data={shipment} />
          </React.Fragment>
        ))}
      </SimpleGrid>
    </div>
  );
});
