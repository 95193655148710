import React, { lazy, Suspense } from "react";
import RouteFallback from "./fallback.routes";
import Shopify from "../channel/shopify";
import WooCommerce from "../channel/WooCommerce";
import OpenCart from "../channel/OpenCart";
import PrivateLayout from "../layouts/private.layouts";
const DashboardPage = lazy(() => import("../pages/Dashboard.page"));
const ShipmentStatusPage = lazy(() => import("../pages/Shipmentstatus.page"));
const ShippingPartnersPage = lazy(() =>
  import("../pages/Shippingpartners.page")
);
const PickuppointsPage = lazy(() => import("../pages/Pickuppoints.page"));
const BillingPage = lazy(() => import("../pages/Billing.page"));
const SettingsPage = lazy(() => import("../pages/Settings.page"));
const AWBstatusPage = lazy(() => import("../pages/AWBstatus.page"));
const CalculatePage = lazy(() => import("../pages/Calculator.page"));
const WeightManagement = lazy(() => import("../pages/WeightManagement.page"));
const RemittancePage = lazy(() => import("../pages/Remittance.page"));
const ChanelPage = lazy(() => import("../pages/Channel.page"));
const B2bRoutesConfig = {
  privateRoute: [
    {
      path: "",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="dashboard">
          <PrivateLayout />
        </Suspense>
      ),
    },
    {
      path: "/dashboard",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="dashboard">
          <DashboardPage />
        </Suspense>
      ),
    },
    {
      path: "/shipmentstatus",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="shipmentstatus">
          <ShipmentStatusPage />
        </Suspense>
      ),
    },
    // {
    //   path: "/manageinvoices",
    //   exact: true,
    //   restricted: true,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="manageinvoices">
    //       <DashboardPage />
    //     </Suspense>
    //   ),
    // },

    // {
    //   path: "/shippingpartners",
    //   exact: true,
    //   restricted: true,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="shippingpartners">
    //       <ShippingPartnersPage />
    //     </Suspense>
    //   ),
    // },
    // {
    //   path: "/pickuppoints",
    //   exact: true,
    //   restricted: true,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="pickuppoints">
    //       <PickuppointsPage />
    //     </Suspense>
    //   ),
    // },
    // {
    //   path: "/billing",
    //   exact: true,
    //   restricted: true,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="billing">
    //       <BillingPage />
    //     </Suspense>
    //   ),
    // },
    // {
    //   path: "/weightmanagement",
    //   exact: true,
    //   restricted: true,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="weightmanagement">
    //       <WeightManagement />
    //     </Suspense>
    //   ),
    // },
    // {
    //   path: "/settings",
    //   exact: true,
    //   restricted: true,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="settings">
    //       <SettingsPage />
    //     </Suspense>
    //   ),
    // },
    // {
    //   path: "/channel",
    //   exact: true,
    //   restricted: true,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="chanel">
    //       <ChanelPage />
    //     </Suspense>
    //   ),
    // },
    // {
    //   path: "/channel/setup/shopify",
    //   exact: true,
    //   restricted: true,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="chanel">
    //       <Shopify />
    //     </Suspense>
    //   ),
    // },
    // {
    //   path: "/channel/setup/wooCommerce",
    //   exact: true,
    //   restricted: true,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="chanel">
    //       <WooCommerce />
    //     </Suspense>
    //   ),
    // },
    // {
    //   path: "/channel/setup/openCart",
    //   exact: true,
    //   restricted: true,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="chanel">
    //       <OpenCart />
    //     </Suspense>
    //   ),
    // },
    // {
    //   path: "/status/:awbNumber",
    //   exact: true,
    //   restricted: false,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="awbstatus">
    //       <AWBstatusPage />
    //     </Suspense>
    //   ),
    // },
    // {
    //   path: "/calculatePrice",
    //   exact: true,
    //   restricted: false,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="calculateprice">
    //       <CalculatePage />
    //     </Suspense>
    //   ),
    // },
    // {
    //   path: "/remittance",
    //   exact: true,
    //   restricted: false,
    //   element: (
    //     <Suspense fallback={<RouteFallback />} key="remittance">
    //       <RemittancePage />
    //     </Suspense>
    //   ),
    // },
  ],
};

export default B2bRoutesConfig;
