import React, { useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { getSelectedShipmentInfo } from "../../redux/slices/BookingDetailsSlice";
import { FcPrint } from "react-icons/fc";
import BookedManifestSample from "./BookedManifestSample";
const BookedManifestModel = ({ onClose, isOpen }) => {
  const selectedShipment = useSelector(getSelectedShipmentInfo);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Modal
      onClose={onClose}
      size="full"
      isOpen={isOpen}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Manifest
          <IconButton icon={<FcPrint />} color="blue" onClick={handlePrint} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ComponentToPrint
            ref={componentRef}
            ShipmentsToPrint={selectedShipment}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BookedManifestModel;

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const { ShipmentsToPrint } = props;
  return (
    <div ref={ref}>
      <BookedManifestSample data={ShipmentsToPrint} />
    </div>
  );
});
