import React from "react";
import { Controller } from "react-hook-form";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Button, FormControl, Flex } from "@chakra-ui/react";
import { DateRange } from "react-date-range";
import "./daterange.scss";

const today = new Date();

const DateRangePickerComponent = ({ control, setValue }) => {
  const refDateRangebutton = React.useRef(null);
  const refDateRangeContainer = React.useRef(null);
  const [showDateRangePicker, setShowDateRangePicker] = React.useState(false);
  const [showCalender, setShowCalender] = React.useState(false);
  const [dateRanges, setDateRanges] = React.useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  React.useEffect(() => {
    document.addEventListener("click", hideOnClickOutside, true);
    return () => {
      document.removeEventListener("click", hideOnClickOutside, true);
    };
  }, []);
  const hideOnClickOutside = (e) => {
    if (
      refDateRangebutton &&
      refDateRangeContainer.current &&
      !refDateRangeContainer.current.contains(e.target)
    ) {
      setShowDateRangePicker(false);
    }
  };
  const startDate = new Date(dateRanges?.[0]?.startDate);
  const endDate = new Date(dateRanges?.[0]?.endDate);
  const formattedStartDate = `${startDate?.getDate()}/${startDate?.getMonth()}/${startDate?.getFullYear()}`;
  const formattedEndDate = `${endDate?.getDate()}/${endDate?.getMonth()}/${endDate?.getFullYear()}`;

  const handleSelectToday = () => {
    setDateRanges([
      {
        startDate: today,
        endDate: today,
        key: "selection",
      },
    ]);

    setValue("dateRange", {
      startDate: today,
      endDate: today,
    });
    setShowDateRangePicker(false);
  };
  const handleSelectYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    setDateRanges([
      {
        startDate: yesterday,
        endDate: yesterday,
        key: "selection",
      },
    ]);

    setValue("dateRange", {
      startDate: yesterday,
      endDate: yesterday,
    });
    setShowDateRangePicker(false);
  };
  const handleSelectLastWeek = () => {
    const lastDayOfPrevWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() - 1
    );
    const firstDayOfLastWeek = new Date(lastDayOfPrevWeek);
    firstDayOfLastWeek.setDate(lastDayOfPrevWeek.getDate() - 6); // Set to the first day of the last week

    setDateRanges([
      {
        startDate: firstDayOfLastWeek,
        endDate: lastDayOfPrevWeek,
        key: "selection",
      },
    ]);

    setValue("dateRange", {
      startDate: firstDayOfLastWeek,
      endDate: lastDayOfPrevWeek,
    });
    setShowDateRangePicker(false);
  };

  const handleSelectCurrentMonth = () => {
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    setDateRanges([
      {
        startDate: firstDayOfMonth,
        endDate: lastDayOfMonth,
        key: "selection",
      },
    ]);

    setValue("dateRange", {
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
    });
    setShowDateRangePicker(false);
  };
  const handleSelectPrevMonth = () => {
    const firstDayOfPrevMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastDayOfPrevMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      0
    );

    setDateRanges([
      {
        startDate: firstDayOfPrevMonth,
        endDate: lastDayOfPrevMonth,
        key: "selection",
      },
    ]);

    setValue("dateRange", {
      startDate: firstDayOfPrevMonth,
      endDate: lastDayOfPrevMonth,
    });
    setShowDateRangePicker(false);
  };
  const handleSelectLast90Days = () => {
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(today.getDate() - 89);

    setDateRanges([
      {
        startDate,
        endDate: today,
        key: "selection",
      },
    ]);

    setValue("dateRange", {
      startDate,
      endDate: today,
    });
    setShowDateRangePicker(false);
  };

  const handleSelectCuston = () => {
    setShowDateRangePicker(false);
    setShowCalender((prev) => !prev);
  };
  const handleDateButton = () => {
    setShowDateRangePicker((prev) => !prev);
    setShowCalender(false);
  };
  const DateRangeButton = [
    { label: "Today", fun: handleSelectToday },
    { label: "Yesterday", fun: handleSelectYesterday },
    { label: "Last Week", fun: handleSelectLastWeek },
    { label: "This Month", fun: handleSelectCurrentMonth },
    { label: "Last Month", fun: handleSelectPrevMonth },
    { label: "Last 90 Days", fun: handleSelectLast90Days },
    { label: "Custom", fun: handleSelectCuston },
  ];
  return (
    <Flex pos={"relative"}>
      <Button
        w={220}
        onClick={handleDateButton}
        colorScheme="blue"
        variant="outline"
        ref={refDateRangebutton}
      >
        {`${formattedStartDate} - ${formattedEndDate} `}
      </Button>

      <Controller
        name="dateRange"
        control={control}
        defaultValue={null}
        render={({ field }) => (
          <>
            {showDateRangePicker && (
              <Flex
                flexDir={"column"}
                pos={"absolute"}
                rounded={"md"}
                w={220}
                top={12}
                p={2}
                gap={1}
                bg={"#274a69"}
                zIndex={20}
                ref={refDateRangeContainer}
              >
                {DateRangeButton?.map((item) => (
                  <Button
                    onClick={item?.fun}
                    key={item?.label}
                    colorScheme="whiteAlpha"
                  >
                    {item.label}
                  </Button>
                ))}
              </Flex>
            )}
            {showCalender && (
              <FormControl pos={"absolute"} top={5} zIndex={99}>
                <DateRange
                  {...field}
                  editableDateInputs
                  footerContent={false}
                  onChange={(item) => {
                    setDateRanges([item.selection]);
                    setValue("dateRange", item.selection);
                  }}
                  ranges={dateRanges}
                  className="calendarElement"
                />
              </FormControl>
            )}
          </>
        )}
      />
    </Flex>
  );
};

export default DateRangePickerComponent;
