import axios from "axios";
export const httpCall = async (config) => {
  let headerConfig;
  if (config?.headers) {
    headerConfig = {
      ...config,
      headers: {
        ...config.headers,
        "Cache-Control": "no-store,no-cache,",
        Pragma: "no-cache",
      },
    };
  } else {
    headerConfig = {
      ...config,
      headers: {
        "Cache-Control": "no-store,no-cache,",
        Pragma: "no-cache",
      },
    };
  }
  try {
    const response = await axios(headerConfig);
    return {
      success: true,
      data: response.data,
      ...response,
    };
  } catch (err) {
    return { success: false, err: err.response };
  }
};

export const GET_CUSTOMER_INFO_URL = "/customers/getCustomerInfo";
export const REGISTER_PICKUP_LOCATION_URL = "/address/registerPickupAdd";
export const UPDATE_CUSTOMER_ADDRESS_URL = "/address/updatePickupAdd";
export const GETALL_PICKUP_LOCATION_URL = "/address/getAllPickupAdd";
export const GET_ALL_PICKUP_URL = "/address/getByCustomerId";
export const ADD_KYC_DETAILS_URL = "/customers/kyc";
export const GET_KYC_DETAILS_URL = "/customers/getCustomerDocs";
export const UPDATE_KYC_DETAILS_URL = "/customers/updateDocuments";
export const GET_SUBSCRIBER_INFO_URL = "/subscriber/getUserInfo";
export const SEND_OTP_URL = "/verify/sentotp";
export const RESEND_OTP_URL = "/verify/resendotp";
export const VARIFY_OTP_URL = "/verify/user";
export const UPDATE_PASSWORD_URL = "/verify/changePassword";
export const LOGIN_URL = "/auth";
export const DOWNLOAD_BOOKING_SAMPLEFILE_URL =
  "/shipment/downloadBookingSampleFile";
export const UPLOAD_BOOKING_URL = "/shipment/uplaodBookingFile";
export const CANCEL_SHIPMENT_URL = "/shipment/cancelBooking";
export const UPLOAD_TRANSACTION_IMAGE_URL =
  "/transaction/uploadTransactionImage";
export const CALCULATE_PRICE_URL = "/serviceCharge/calculatePrice/B2C";
export const SINGLE_BOOKING_URL = "/shipment/singleShipmentBooking";
export const GET_ALL_SHIPMENT_URL = "/shipment/getAllShipmentInfo";
export const HANDLE_DELETE_IN_PICKUPTABLE_URL = "/address/deletePickupAdd";
export const EDIT_PICKUP_FORMDATA_URL = "/address/updatePickupAdd";
export const getUserData = "/getPickupAddInfo/:id";
export const UPDATE_CUSTOMER_INFO_URL = "/customers/updateCustomer";
////////////// payment Link
export const GET_PAYMENT_TOKEN_URL =
  "https://pay.easebuzz.in/payment/initiateLink";
export const GET_PAYMENT_ACCESS_KEY_URL = "/payment/accessKey";
export const SAVE_TRANSACTION_URL = "/transaction/registerTransactionDetails";
export const GET_ALL_IDENTITY_URL = "/identityType/getAllIdentityType";
export const GET_AWB_STATUS_URL = "/shipmentStatus/statusTrack";

export const GETALL_TRANSACTIONS_URL =
  "/transaction/allTransactionStatusByCustomer";

export const GET_DASHBOARD_DATA_URL = "/dashboard/customer";

export const UPDATE_BANKING_DETAILS_URL =
  "/customerBankDetails/updateBankDetails";
export const GET_BANKING_DETAILS_URL = "/customerBankDetails/getBankDetails";
export const GET_DISCREPANCY_DETAILS_URL = "/discrepancy/shipments";

export const GET_ALL_REMITTANCE_URL = "/pay/getAllTransaction";
export const SAVE_DEFAULT_ADDRESS_URL = "/settings/address";
export const SAVE_DEFAULT_BANKACCOUNT_URL = "/settings/default/bankAccount";
export const SAVE_COURIER_SETTINGS_URL = "/settings/courier";
export const SAVE_PACKAGE_VOLUME_URL = "/settings/package";
export const SAVE_MANIFEST_SETTING_URL = "/settings/manifestSetting";
export const SAVE_FULFILMENT_SETTING_URL = "/settings/channel";
export const GET_COURIER_SETTINGS_URL = "/settings";
export const GET_PINCODE_DATA_URL = "/pincodeCoverage/getPincodeInfo";
