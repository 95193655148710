import { Flex, useMediaQuery, HStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getLoggedUser,
  setLoggedUserDetails,
} from "../../redux/slices/UserDetailsSlice";
import Calculatedrawer from "../Calculator/Calculaterdrawer";
import UserMenu from "./UserMenu";
import WalletMenu from "./WalletMenu";
import useDebounce from "../../utils/Debaunce";
import MobileMenu from "../../components/sidebar/MobileMenu";
import QuixgoText from "../../components/shared/QuixgoText";
import NavbarSearch from "./NavbarSearch";
export default function Navbar() {
  const checkUrl = window.location.href.includes("/status/");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const [inputValue, setAwbNumber] = useState("");
  const debouncedAwbNumberValue = useDebounce(inputValue, 1000);
  const [isLessThan540] = useMediaQuery("(max-width: 540px)");
  const [isLessThan768] = useMediaQuery("(max-width: 769px)");
  useEffect(() => {
    if (debouncedAwbNumberValue?.length > 7) {
      navigate(`/status/${debouncedAwbNumberValue.trim()}`, { replace: true });
    }
  }, [debouncedAwbNumberValue, navigate]);
  useEffect(() => {
    if (!checkUrl) {
      setAwbNumber("");
    }
  }, [checkUrl]);
  const handleAwbChange = (e) => {
    setAwbNumber(e.target.value);
  };
  return (
    <>
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        w={"100%"}
        px={[0, 5]}
      >
        <Flex alignItems={"center"}>
          {isLessThan768 && <MobileMenu />} <QuixgoText fontSize={["3xl"]} />
        </Flex>
        <HStack spacing={4}>
          {!isLessThan540 && (
            <NavbarSearch
              placeholder="Search AWB..."
              onChange={handleAwbChange}
            />
          )}
          <Calculatedrawer />
          <WalletMenu />
          <UserMenu loggedUser={loggedUser} />
        </HStack>
      </Flex>
      {isLessThan540 && (
        <NavbarSearch
          placeholder="Search AWB..."
          onChange={handleAwbChange}
          w={"full"}
          roundedBottom={"none"}
        />
      )}
    </>
  );
}
