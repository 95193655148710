import React, { useState } from "react";
import { Icon } from "@chakra-ui/icons";
import { BsWhatsapp } from "react-icons/bs";
import useDraggable from "../hooks/useDrag";
import { Box } from "@chakra-ui/layout";

const Whatsapp = () => {
  const [isDragging, setIsDragging] = useState(false);
  const buttonRef = useDraggable(setIsDragging);
  const handleClick = () => {
    if (!isDragging) {
      window.open("https://wa.link/azk0d2", "_blank", "noopener");
    }
  };

  return (
    <Box
      ref={buttonRef}
      position={"absolute"}
      right={"100px"}
      bottom={"100px"}
      w={"80px"}
      h={"80px"}
      color={"#fff"}
      rounded={"50%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      cursor={"move"}
      userSelect={"none"}
      bg={"#E7E7EF"}
      zIndex={999}
    >
      <Icon
        as={BsWhatsapp}
        boxSize="3.5rem"
        color="white"
        bg="#25d366"
        rounded="full"
        onClick={handleClick}
        zIndex={999}
        cursor={"pointer"}
      />
    </Box>
  );
};

export default Whatsapp;
