import React, { Component } from "react";
import { Box, Button, Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import somethingwrong from "./assets/images/somethingwrong.jpg";

class ErrorBoundary extends Component {
  state = {
    hasError: null,
    errorDetails: null,
  };

  refreshPage = () => {
    window.location.reload(false);
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: error.message, // Displaying only the main error message
      errorDetails: errorInfo.componentStack.toString(),
    });
  }
  render() {
    if (this.state.hasError) {
      return (
        <>
          <Image
            src={somethingwrong}
            alt="Quixo error boundary image"
            w={1000}
            mx={"auto"}
            objectFit={"cover"}
          />
          <Flex
            textAlign="center"
            flexDir={"column"}
            fontFamily="slab serif"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text fontSize={"3xl"}>An error occurred: </Text>
            <Text
              color={"red"}
              fontSize={["2vh", "4vh"]}
              fontWeight={"bold"}
              whiteSpace={"break-spaces"}
            >
              {this.state.hasError}
              {console.log(this.state)}
            </Text>
            <Button w={150} onClick={this.refreshPage} colorScheme="blue">
              Click To Reload!
            </Button>
          </Flex>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
