import React, { useState, useEffect } from "react";
import {
  Button,
  Textarea,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";

import { useSelector, useDispatch } from "react-redux";
import { getPickupLocations } from "../../../redux/slices/BookingDetailsSlice";
import { getCustomerInfo } from "../../../redux/slices/CustomerDetailsSlice";
import { ServiceProviderList } from "../../../utils/utilityConstants";
const ReverseButton = ({ row, onClose, isOpen }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const pickupLocations = useSelector(getPickupLocations);
  const customerInfo = useSelector(getCustomerInfo);
  const [msg, setMsg] = useState();
  const [shippingPartner, setShippingPartner] = useState({});
  const cancelRef = React.useRef();
  useEffect(() => {
    const shipPartner = row.shipmentPartner;
    const serviceProvider = ServiceProviderList.filter((ship) => {
      return ship.name === shipPartner?.toUpperCase();
    });
    setShippingPartner(serviceProvider[0]?.value);
  }, [row]);
  const handleConfirmationClick = () => {
    let services = "SF";
    const pickUpAddress = row
      ? pickupLocations.filter(
          (pickup) => pickup.addressId === row.pickupAddress
        )[0]
      : {};
    const params = {
      deliveryAddress: {
        name: pickUpAddress?.cpPerson,
        address1: pickUpAddress?.address1,
        address2: pickUpAddress?.address2,
        landmark: pickUpAddress?.landmark,
        city: pickUpAddress?.city,
        state: pickUpAddress?.state,
        pincode: pickUpAddress.pincode || pickUpAddress.pin,
        mobile: pickUpAddress.cpMobile,
        email: pickUpAddress.email,
        addressType: pickUpAddress.addressType,
        country: pickUpAddress.country ? pickUpAddress.country : "India",
      },
      pickupAddress: {
        cpPerson: row.deliveryAddress_name,
        address1: row.deliveryAddress_address1,
        address2: row.deliveryAddress_address2,
        landmark: row.deliveryAddress_lmk,
        city: row.deliveryAddress_city,
        state: row.deliveryAddress_state,
        pincode: row.deliveryAddress_pincode,
        cpMobile: row.deliveryAddress_mobile,
        email: row.deliveryAddress_email,
        addressType: row.deliveryAddress_addressType,
        country: "India",
      },
      customerType: "",
      productDetails: {
        weight: row.productDetails_weight,
        height: row.productDetails_height,
        width: row.productDetails_width,
        length: row.productDetails_length,
        invoice: row.productDetails_invoice,
        productName: row.productDetails_productName,
        productType: row.productDetails_productType,
        quantity: row.productDetails_quantity,
        codAmount: row.productDetails_cod,
        skuNumber: row.productDetails_skuNumber,
        orderNumber: row.productDetails_orderNumber,
        ewayBill: row.productDetails_ewayBill,
      },
      serviceProvider: shippingPartner,
      serviceType: services,
      paymentMode: "Prepaid",
      customerId: row.customerId,
      serviceMode: "reverse",
      awbNumber: row.awbNumber,
    };

    if (
      Number(customerInfo.walletAmount) < Number(customerInfo.minimumBalance)
    ) {
      toast({
        title: "Low Balance",
        description: "Please recharge your wallet.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      dispatch({ type: "SINGLE_SHIPMENT_BOOKING", postData: params });
      onClose();
    }
    onClose();
  };
  const onchangeServiceProvider = (serviceProvider) => {
    setShippingPartner(serviceProvider);
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Reverse Shipment {row.awbNumber}
            </AlertDialogHeader>
            <AlertDialogBody>
              <FormLabel htmlFor="serviceProvider">
                Service Provider
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Select
                id="serviceProvider"
                placeholder="Select Service Provider"
                onChange={(e) => onchangeServiceProvider(e.target.value)}
                value={shippingPartner}
              >
                {ServiceProviderList.map((provider, i) => (
                  <option key={provider.value} value={provider.value}>
                    {provider.name}
                  </option>
                ))}
              </Select>
              Please Enter "Reverse" and press confirm.
              <Textarea
                placeholder="Please write Reverse and confirm"
                onChange={(e) => setMsg(e.target.value)}
              />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleConfirmationClick}
                ml={3}
                isDisabled={!msg || (msg && msg.toLowerCase() !== "reverse")}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ReverseButton;
