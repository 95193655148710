import { createSlice } from "@reduxjs/toolkit";
export const PaymentDetailsSlice = createSlice({
  name: "PaymentDetailsSlice",
  initialState: {
    paymentAccessKey: null,
    isPaymentLoader: false,
    allTransactionList: [],
    isPaymentDetailsLoading: false,
  },
  reducers: {
    setPaymentAccessKey: (state, action) => {
      state.paymentAccessKey = action.payload;
    },
    setIsPaymentLoader: (state, action) => {
      state.isPaymentLoader = action.payload;
    },
    setAllTransactionList: (state, action) => {
      state.allTransactionList = action.payload;
    },
    setPaymentDetailsLoading: (state, action) => {
      state.isPaymentDetailsLoading = action.payload;
    },
  },
});
export const {
  setPaymentAccessKey,
  setIsPaymentLoader,
  setAllTransactionList,
  setPaymentDetailsLoading,
} = PaymentDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getPaymentAccessKey = (state) =>
  state.PaymentDetailsSlice.paymentAccessKey;
export const getIsPaymentLoader = (state) =>
  state.PaymentDetailsSlice.isPaymentLoader;
export const getAllTransactionList = (state) =>
  state.PaymentDetailsSlice.allTransactionList;
export const getPaymentDetailsLoading = (state) =>
  state.PaymentDetailsSlice.isPaymentDetailsLoading;

///we can also write thunks by hand , which may contain both sync and async logic

export default PaymentDetailsSlice.reducer;
