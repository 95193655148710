import React from "react";
import {
  Heading,
  Stack,
  Text,
  Checkbox,
  CheckboxGroup,
  VStack,
} from "@chakra-ui/react";
import {
  CustomerSellPoint,
  ProductCategory,
  ShipmentType,
} from "../../../utils/utilityConstants";
const CustomerSellMedia = ({ onChange, value: defaultValue }) => {
  const handleOnChange = (value, id) => {
    const mediaValue = { ...defaultValue };
    mediaValue[id] = value;
    onChange("media", mediaValue);
  };
  return (
    <>
      <Heading as="h2" size={"lg"} color="blackAlpha.800" mb={4}>
        {" "}
        How do you sell your product ?
        <Text as="span" color="red">
          *
        </Text>
        <Text as="span" color={"black.700"} fontSize="lg">
          (Select as many as applicable)
        </Text>
      </Heading>
      <CheckboxGroup
        id="sellPoint"
        colorScheme="blue"
        defaultValue={defaultValue.sellPoint}
        onChange={(value) => handleOnChange(value, "sellPoint")}
      >
        <Stack spacing={4} direction={"column"}>
          <Checkbox value={CustomerSellPoint.MARKET_PLACE}>
            Online Marketplaces (like Amazon, Flipkart etc.)
          </Checkbox>
          <Checkbox value={CustomerSellPoint.WEBSITE}>Own Website</Checkbox>
          <Checkbox value={CustomerSellPoint.SOCIAL}>
            Social Media (like Facebook, Instagram, WhatsApp)
          </Checkbox>
          <Checkbox value={CustomerSellPoint.RETAILS}>
            Retail/Physical stores
          </Checkbox>
        </Stack>
      </CheckboxGroup>
      <Heading as="h2" size={"lg"} color="blackAlpha.800" my={4}>
        {" "}
        Please choose the product category you deal in.
        <Text as="span" color="red">
          *
        </Text>
        <br />
        <Text as={"span"} color={"black.700"} fontSize="lg">
          (Select as many as applicable)
        </Text>
      </Heading>
      <CheckboxGroup
        colorScheme="blue"
        id="productCategory"
        defaultValue={defaultValue.productCategory}
        onChange={(value) => handleOnChange(value, "productCategory")}
      >
        <Stack spacing={4} direction={"column"}>
          <Checkbox value={ProductCategory.CLOTHS}>Cloths/Dresses</Checkbox>
          <Checkbox value={ProductCategory.ELECTRONICS}>
            Mobiles/Laptop/Television
          </Checkbox>
          <Checkbox value={ProductCategory.ELECTRONIC_PARTS}>
            Electronic items and their accessories (like earphones, mouse,
            speakers, TV parts, etc.)
          </Checkbox>
          <Checkbox value={ProductCategory.PACKAGED_FOOD}>
            Packaged Food Items
          </Checkbox>
          <Checkbox value={ProductCategory.HOME_KITCHEN}>
            Home/Kitchen/Pets ( kitchen Items, Bed Sheet,blankets, etc.)
          </Checkbox>
          <Checkbox value={ProductCategory.HEALTH_BEAUTY}>
            Health Products / Beauty Products (like Face cream,Mask,Chawanprash
            , etc.)
          </Checkbox>
          <Checkbox value={ProductCategory.SPORTS_FITNESS}>
            Sports/Fitness/Bags/Luggage
          </Checkbox>
          <Checkbox value={ProductCategory.KIDS}>
            Toys/Baby Products/Kids Fashion
          </Checkbox>
          <Checkbox value={ProductCategory.INDUSTRIAL}>
            Car/Motorbike/Industrial Parts
          </Checkbox>
          <Checkbox value={ProductCategory.OTHER}>Others</Checkbox>
        </Stack>
      </CheckboxGroup>
      <Heading as="h2" size={"lg"} color="blackAlpha.800" mt={4}>
        What kind of service you required ?
        <Text as="span" color="red">
          *
        </Text>
      </Heading>
      <Text fontSize={"lg"} letterSpacing={1.5} ml={[0, 0, 5]} mb={4}>
        ( Select All which required )
      </Text>
      <CheckboxGroup
        id="shipmentType"
        onChange={(value) => handleOnChange(value, "shipmentType")}
        value={defaultValue.shipmentType}
      >
        <VStack spacing={4} align="stretc.h">
          {ShipmentType?.map((item) => (
            <React.Fragment key={item?.value}>
              <Checkbox value={item?.value}>{item?.label}</Checkbox>
            </React.Fragment>
          ))}
        </VStack>
      </CheckboxGroup>
    </>
  );
};

export default CustomerSellMedia;
