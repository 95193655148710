export const OfferRecharge = [
  {
    value: '1000',
    label: ' you will get 2% cashback into your wallet',
  },
  {
    value: '2000',
    label: ' you will get 3% cashback into your wallet',
  },
  {
    value: '5000',
    label: ' you will get 4% cashback into your wallet',
  },
  {
    value: '10000',
    label: ' you will get 5% cashback into your wallet',
  },
  {
    value: '25000',
    label: ' you will get 7% cashback into your wallet',
  },
  {
    value: '50000',
    label: ' you will get 10% cashback into your wallet',
  },
];
