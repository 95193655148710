import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { COURIER_SERVICE_LOGIN } from "../utils/constant";
import {
  setLoggedUserDetails,
  getLoggedUser,
  getUserLoader,
} from "../redux/slices/UserDetailsSlice";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
export default function LoginSplitScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const from = location.state?.from?.pathname || "/";
  const loggedUser = useSelector(getLoggedUser);
  const isLoggedUser = useSelector(getUserLoader);
  useEffect(() => {
    const isLoggedIn = JSON.parse(localStorage.getItem(COURIER_SERVICE_LOGIN));
    if (isLoggedIn) {
      dispatch(setLoggedUserDetails(isLoggedIn));
    }
  }, [dispatch]);
  useEffect(() => {
    if (loggedUser?.token) {
      navigate(from, { replace: true });
    }
  }, [loggedUser, navigate, from]);

  const initialValues = {
    email: "",
    password: "",
    remember: false,
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(/^[a-z0-9+_.-]+@[a-z]+\.[a-z]{2,3}/, "Invalid email id.")
      .required("Required"),
    password: Yup.string().required("No password provided."),
    remember: Yup.boolean(),
  });
  const onSubmit = (data) => {
    let userParams = {
      email: data.email,
      password: data.password,
      remember: data.remember,
    };
    dispatch({ type: "LOGIN_USER", postData: userParams });
  };
  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={4} w={"full"} maxW={"md"}>
          <Image
            w={"150px"}
            objectFit="cover"
            src="/quixgo.jpg"
            alt="Quixgo"
            mb={"50px"}
          />
          <Heading fontSize={"2xl"}>Sign in to your account</Heading>
          <Formik
            initialValues={initialValues}
            //   onSubmit={(values)=>onSubmit(values)}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              onSubmit(values);
            }}
          >
            {(props) => (
              <Form>
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      id="email"
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel>Email address</FormLabel>
                      <Input
                        placeholder="Your email address"
                        type="email"
                        {...field}
                        autoComplete={"username"}
                        tabIndex="0"
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl
                      id="password"
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel>Password</FormLabel>
                      <InputGroup size="md" {...field}>
                        <Input
                          pr="4.5rem"
                          type={show ? "text" : "password"}
                          autoComplete={"current-password"}
                          tabIndex="1"
                          placeholder="Enter password"
                        />
                        <InputRightElement
                          onClick={() => setShow(!show)}
                          children={
                            <Icon
                              as={show ? AiFillEye : AiFillEyeInvisible}
                              w={5}
                              h={5}
                              color="blue.500"
                            />
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Stack spacing={6}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={"start"}
                    justify={"space-between"}
                  >
                    <Link
                      as={NavLink}
                      to="/resetPassword"
                      color={"blue.500"}
                      tabIndex="4"
                    >
                      Forgot password?
                    </Link>
                  </Stack>
                  <Button
                    colorScheme={"blue"}
                    variant={"solid"}
                    type="submit"
                    tabIndex="3"
                    isLoading={isLoggedUser}
                  >
                    Sign in
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image alt={"Login Image"} objectFit={"fill"} src="/web_poster.webp" />
      </Flex>
    </Stack>
  );
}
