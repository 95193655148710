import React, { useCallback } from "react";
import { Controller, useWatch } from "react-hook-form";
import {
  FormControl,
  Input,
  Select,
  FormErrorMessage,
  GridItem,
  Button,
  Text,
} from "@chakra-ui/react";
export const CategorySelect = ({ errors, control, searchCategoryOption }) => {
  const isSearchValue = useWatch({ control, name: "term" });
  return (
    <FormControl isInvalid={errors.category}>
      <Controller
        name="category"
        control={control}
        rules={{
          required: Boolean(isSearchValue) ? "Category is required" : false,
        }}
        render={({ field }) => (
          <Select w={220} placeholder="Select Category" {...field}>
            {searchCategoryOption?.map((item) => (
              <option key={item?.value} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </Select>
        )}
      />
      <FormErrorMessage>
        {errors.category && errors.category.message}
      </FormErrorMessage>
    </FormControl>
  );
};
export const CustomSelect = ({
  errors,
  control,
  selectCategoryOption,
  placeholder,
  valueType,
}) => {
  return (
    <FormControl isInvalid={errors[valueType]}>
      <Controller
        name={valueType}
        control={control}
        render={({ field }) => (
          <Select w={220} placeholder={placeholder} {...field}>
            {selectCategoryOption?.map((item) => (
              <option key={item?.value} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </Select>
        )}
      />
      <FormErrorMessage>
        {errors[valueType] && errors[valueType].message}
      </FormErrorMessage>
    </FormControl>
  );
};
export const SearchInCategory = ({ errors, register, control }) => {
  const selectedCategory = useWatch({ control, name: "category" });
  return (
    <FormControl isInvalid={errors.term}>
      <Input
        w={220}
        id="term"
        type="text"
        placeholder="Enter your search term"
        {...register("term", {
          required: selectedCategory
            ? `${selectedCategory} is required`
            : false,
        })}
      />
      <FormErrorMessage>{errors.term && errors.term.message}</FormErrorMessage>
    </FormControl>
  );
};

export const ComparisonCategory = ({ errors, control, setValue }) => {
  const selectedCategory = useWatch({ control, name: "category" });
  const Category = [
    "minimumBalance",
    "walletAmount",
    "cashBack",
    "creditLimit",
  ];
  const showThisForm = Category?.includes(selectedCategory);
  React.useEffect(() => {
    setValue("operator", "");
  }, [selectedCategory]);
  return showThisForm ? (
    <GridItem w={220}>
      <FormControl isInvalid={errors.operator}>
        <Controller
          name="operator"
          control={control}
          rules={{
            required: "This is required",
          }}
          render={({ field }) => (
            <Select w={220} placeholder="Value Is" {...field}>
              <option value="gt">Greater Than</option>
              <option value="gte">Greater Than Equal</option>
              <option value="eq">Equal To</option>
              <option value="ne">Not Equal To</option>
              <option value="lt">Less Than</option>
              <option value="lte">Less Than Equal</option>
            </Select>
          )}
        />
        <FormErrorMessage>
          {errors.operator && errors.operator.message}
        </FormErrorMessage>
      </FormControl>
    </GridItem>
  ) : null;
};

export const ToggleFilterButton = ({
  showMoreFilterComponent,
  setShowMoreFilterComponent,
}) => {
  const handleToggle = useCallback(() => {
    setShowMoreFilterComponent((prev) => !prev);
  }, [setShowMoreFilterComponent]);

  const renderButtonText = () => {
    if (showMoreFilterComponent) {
      return (
        <>
          <Text fontSize={25} as="span">
            -{" "}
          </Text>
          Less Filter
        </>
      );
    } else {
      return (
        <>
          <Text fontSize={25} as="span">
            +
          </Text>
          More Filter
        </>
      );
    }
  };

  return (
    <GridItem>
      <Button
        w={220}
        type="submit"
        onClick={handleToggle}
        colorScheme="blue"
        variant="outline"
      >
        {renderButtonText()}
      </Button>
    </GridItem>
  );
};
