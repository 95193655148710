import { Flex } from "@chakra-ui/layout";
import React from "react";

const OpenCart = () => {
  return (
    <Flex p={5} fontSize={"2xl"} color={'black'} fontWeight={"bold"}>
      Page Under Development
    </Flex>
  );
};

export default OpenCart;
