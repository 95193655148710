import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  setTransactionDetailsLoading,
  setRemittancePaidList,
} from "../slices/TransactionDetailsSlice";
import { httpCall, GET_ALL_REMITTANCE_URL } from "../../utils/api";
import { AUTH_USER_CG_TOKEN_KEY } from "../../utils/constant";
import { toast } from "react-toastify";

export function* getRemittanceTransactions(params) {
  let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  let { payload } = params;
  const getAllRemittanceUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_REMITTANCE_URL}`;
  yield put(setTransactionDetailsLoading(true));
  try {
    const getAllRemittanceResponse = yield call(httpCall, {
      url: getAllRemittanceUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: payload,
    });
    if (getAllRemittanceResponse?.success) {
      yield put(setRemittancePaidList(getAllRemittanceResponse?.data));
      yield put(setTransactionDetailsLoading(false));
    } else {
      toast.error("Something went wrong");
      yield put(setTransactionDetailsLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchTransactionDetailsSaga() {
  yield takeLatest("GET_REMITTANCE_TRANSACTION", getRemittanceTransactions);
}

export default function* TransactionDetailsSaga() {
  yield all([watchTransactionDetailsSaga()]);
}
