import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
  Skeleton,
  Checkbox,
} from "@chakra-ui/react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setSelectedShipmentInfo } from "../../redux/slices/BookingDetailsSlice";
const pageColor = "#274a69";
const CustomTable = ({ tableData, styleprops = {} }) => {
  let { PropsData, Header, Body, isDataLoading } = tableData;
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = React.useState(null);
  const [sortOrder, setSortOrder] = React.useState("asc");
  const [checkedItems, setCheckedItems] = React.useState(
    new Array(PropsData?.length).fill(false)
  );
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  if (sortBy) {
    PropsData = PropsData ? PropsData.slice() : [];
    PropsData.sort((a, b) => {
      const valueA = a?.[sortBy];
      const valueB = b?.[sortBy];
      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (typeof valueA === "number" && typeof valueB === "number") {
        return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
      } else {
        return sortOrder === "asc"
          ? String(valueA).localeCompare(String(valueB))
          : String(valueB).localeCompare(String(valueA));
      }
    });
  }
  const handleSort = (header) => {
    if (sortBy === header) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(header);
      setSortOrder("asc");
    }
  };
  const handleCheckboxChange = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
    const selectedData = PropsData?.filter((_, i) => newCheckedItems[i])?.map(
      (item) => item
    );
    dispatch(setSelectedShipmentInfo(selectedData));
  };
  const handleHeaderCheckboxChange = (isChecked) => {
    const newCheckedItems = new Array(PropsData?.length).fill(isChecked);
    setCheckedItems(newCheckedItems);
    const selectedData = PropsData?.filter((_, i) => newCheckedItems[i])?.map(
      (item) => item
    );
    dispatch(setSelectedShipmentInfo(selectedData));
  };
  return (
    <TableContainer pos={"relative"} {...styleprops}>
      <Table variant="simple">
        <Thead pos={"sticky"} top={0} bg={pageColor} color={"white"}>
          <Tr>
            <Th>
              <Checkbox
                isChecked={allChecked}
                isIndeterminate={isIndeterminate}
                onChange={(e) => handleHeaderCheckboxChange(e.target.checked)}
              />
            </Th>
            {Header?.map((item) => (
              <Th
                onClick={() => handleSort(item?.sortByTerm)}
                key={item?.label}
                color={"whiteAlpha.800"}
              >
                {item?.label}
                {sortBy === item?.sortByTerm && (
                  <Icon
                    as={
                      sortOrder === "asc"
                        ? AiOutlineArrowUp
                        : AiOutlineArrowDown
                    }
                    ml={1}
                    boxSize={3}
                    color="white"
                  />
                )}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isDataLoading ? (
            <Tr>
              <Td colSpan={Header.length}>
                <Skeleton height={"20px"} />
              </Td>
            </Tr>
          ) : (
            PropsData?.map((item, index) => (
              <Tr key={index}>
                <Td>
                  <Checkbox
                    isChecked={checkedItems[index]}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </Td>
                {Body?.map((Component, idx) => (
                  <Td key={idx}>{<Component key={idx} row={item} />}</Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(CustomTable);
