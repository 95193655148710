import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Stack,
  SimpleGrid,
  Box,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { ProductType, ServiceProviderList } from "../../utils/utilityConstants";
import {
  getBookingStatus,
  getCoverage,
  getIsBookingDone,
  getPickupLocations,
  getPincodeData,
  getPincodeDataLoading,
  setCoverage,
  setIsBooking,
  setPincodeData,
} from "../../redux/slices/BookingDetailsSlice";
import { getCustomerInfo } from "../../redux/slices/CustomerDetailsSlice";
import { useSelector, useDispatch } from "react-redux";
import InsurancePolicy from "../../components/insurance-policy/InsurancePolicy";
import useDebounce from "../../utils/Debaunce";
import { toast } from "react-toastify";
import { formatValue } from "../../utils/utilsFunction";
import {
  AlternateMobileFormControl,
  ContactMobileFormControl,
  ContactNameFormControl,
  PaymentModeFormControl,
  PickupPointFormControl,
  ServiceProviderFormControl,
  ServiceTypeFormControl,
  EmailFormControl,
  AddressTypeFormControl,
  Address1FormControl,
  Address2FormControl,
  LandmarkFormControl,
  CityFormControl,
  StateFormControl,
  PincodeFormControl,
  EwayBillFormControl,
  SkuNumberFormControl,
  OrderNumberFormControl,
  ProductNameFormControl,
  ProductTypeFormControl,
  WeightFormControl,
  InvoiceFormControl,
  CodAmountFormControl,
  FormTag,
  QuantityFormControl,
  LengthFormControl,
  WidthFormControl,
  HeightFormControl,
} from "./BookingFormField";
const BookingForm = ({ defaultFormValue, onClose }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const debouncedPincodeValue = useDebounce(inputValue, 500);
  const pickupLocations = useSelector(getPickupLocations);
  const pincodeData = useSelector(getPincodeData);
  const DeliveryData = pincodeData?.coverage;
  const coverageData = useSelector(getCoverage);
  const isPincodeDataLoading = useSelector(getPincodeDataLoading);
  const customerInfo = useSelector(getCustomerInfo);
  const isBookingStatus = useSelector(getBookingStatus);
  const isBookingDone = useSelector(getIsBookingDone);
  const [ServiceTypeOption, setServiceTypeOption] = useState([]);
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [length, setLength] = useState();
  const [insurenceAmount, setInsurenceAmount] = useState();
  const [insuranceValue, setInsuranceValue] = useState();
  const [pickupCode, setPickupCode] = useState();
  const [paymentMode, setPaymentMode] = useState(
    defaultFormValue?.paymentMode || "Prepaid"
  );
  const [ServiceProvider, setServiceProvider] = useState(
    defaultFormValue?.serviceProvider
  );
  const [deliveryCoverage, setDeliveryCoverage] = useState(false);
  const [pickupCoverage, setPickupCoverage] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [serviceTypeValue, setServiceTypeValue] = useState(
    defaultFormValue?.serviceType
  );
  const displayToast = () => {
    toast.error(
      `Please recharge your wallet. Minimum Balance should be ${
        customerInfo?.minimumBalance ? customerInfo?.minimumBalance : 250
      }`,
      {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        status: "error",
        theme: "colored",
      }
    );
  };
  useEffect(() => {
    if (
      coverageData &&
      Object.keys(coverageData)?.length > 0 &&
      ServiceProvider
    ) {
      const PickCoverage = Object.fromEntries(
        Object.entries(coverageData)?.filter(([key]) =>
          key.startsWith(`${ServiceProvider}`)
        )
      );
      const fwPkCoverage = JSON.parse(
        PickCoverage?.[`${ServiceProvider}_FW_PK`]
      );

      if (fwPkCoverage) {
        setPickupCoverage(true);
      } else {
        setErrorMsg("Pickup NOT available on this Pickup Point");
      }
    }
  }, [ServiceProvider, coverageData]);

  useEffect(() => {
    if (pickupCoverage && DeliveryData) {
      const DelCoverage = Object.fromEntries(
        Object.entries(DeliveryData)?.filter(([key]) =>
          key.startsWith(`${ServiceProvider}`)
        )
      );
      const preDelCoverage =
        DelCoverage &&
        ServiceProvider &&
        JSON.parse(DelCoverage?.[`${ServiceProvider}_FW_DL_PRE`]);
      const codDelCoverage =
        DelCoverage &&
        ServiceProvider &&
        JSON.parse(DelCoverage?.[`${ServiceProvider}_FW_DL_COD`]);

      if (paymentMode === "Prepaid" && !preDelCoverage) {
        setErrorMsg("Prepaid service NOT available on this Pincode");
        setDeliveryCoverage(false);
      } else if (paymentMode === "COD" && !codDelCoverage) {
        setErrorMsg("COD service NOT available on this Pincode");
        setDeliveryCoverage(false);
      } else {
        setDeliveryCoverage(true);
        setErrorMsg(null);
      }
    }
  }, [ServiceProvider, DeliveryData, paymentMode, pickupCoverage]);
  useEffect(() => {
    if (
      pincodeData &&
      Object.keys(pincodeData).length > 0 &&
      pickupCoverage &&
      !pincodeData?.state &&
      !pincodeData?.city &&
      !pincodeData?.coverage
    ) {
      setErrorMsg("Delivery NOT available on this Pincode");
      setDeliveryCoverage(false);
    }
  }, [pincodeData, pickupCoverage]);
  useEffect(() => {
    if (errorMsg !== undefined && errorMsg !== null) {
      toast.error(`${errorMsg}`, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [errorMsg, ServiceProvider]);
  useEffect(() => {
    const filteredPickupLocations = pickupLocations?.filter(
      (item) => item?.addressId === defaultFormValue?.pickupPoint
    );
    if (defaultFormValue?.pickupPoint) {
      setPickupCode(filteredPickupLocations.map((item) => item?.pincode));
    }
  }, [defaultFormValue]);

  useEffect(() => {
    if (pickupCode) {
      dispatch({ type: "GET_COVERAGE_DATA", postData: pickupCode });
    } else {
      dispatch(setCoverage({}));
    }
  }, [pickupCode]);
  useEffect(() => {
    if (isBookingStatus) {
      onClose();
      dispatch(setIsBooking(false));
    }
  }, [isBookingStatus]);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: defaultFormValue,
  });
  useEffect(() => {
    if (defaultFormValue?.pincode || debouncedPincodeValue?.length > 5) {
      dispatch({
        type: "GET_PINCODE_DATA",
        postData: debouncedPincodeValue || defaultFormValue?.pincode,
      });
    } else {
      dispatch(setPincodeData({}));
    }
  }, [debouncedPincodeValue, defaultFormValue]);

  useEffect(() => {
    const city = pincodeData?.city?.cityName || defaultFormValue?.city;
    const state = pincodeData?.state?.stateName || defaultFormValue?.state;
    if (pincodeData !== undefined && pincodeData !== null) {
      setValue("city", city);
      setValue("state", state);
    } else {
      setValue("city", "");
      setValue("state", "");
    }
  }, [pincodeData]);

  const onInvoiceChange = (value) => {
    const amount = (1 / 100) * Number(value);
    setInsurenceAmount(amount.toFixed(2));
  };
  function onSubmit(values, e) {
    const pickupAddress = pickupLocations.filter(
      (location) => location.addressId === values.pickupPoint
    )[0];

    const params = {
      deliveryAddress: {
        name: values.name.replace(/\s+/g, " ").trim(),
        address1: values.address1.replace(/\s+/g, " ").trim(),
        address2: values.address2.replace(/\s+/g, " ").trim(),
        landmark: values.landmark.replace(/\s+/g, " ").trim(),
        city: values.city.replace(/\s+/g, " ").trim(),
        state: values.state.replace(/\s+/g, " ").trim(),
        pincode: values.pincode.replace(/\s+/g, " ").trim(),
        mobile: values.mobileNumber.replace(/\s+/g, " ").trim(),
        alternateNumber: values.alternateNumber.replace(/\s+/g, " ").trim(),
        email: values.email.replace(/\s+/g, " ").trim(),
        addressType: values.addressType.replace(/\s+/g, " ").trim(),
      },
      pickupAddress,
      customerType: customerInfo.type,
      productDetails: {
        weight: formatValue(values?.weight).replace(/\s+/g, " ").trim(),
        height: formatValue(values?.height).replace(/\s+/g, " ").trim(),
        width: formatValue(values?.width).replace(/\s+/g, " ").trim(),
        length: formatValue(values?.length).replace(/\s+/g, " ").trim(),
        invoice: values.invoice.replace(/\s+/g, " ").trim(),
        productName: values.productName.replace(/\s+/g, " ").trim(),
        productType: values.productType.replace(/\s+/g, " ").trim(),
        quantity: values.quantity,
        codAmount: values.codAmount,
        skuNumber: values.skuNumber.replace(/\s+/g, " ").trim(),
        orderNumber: values.orderNumber.replace(/\s+/g, " ").trim(),
        ewayBill: values.ewayBill.replace(/\s+/g, " ").trim(),
      },
      serviceProvider: values.serviceProvider.replace(/\s+/g, " ").trim(),
      serviceType: values.serviceType.replace(/\s+/g, " ").trim(),
      paymentMode: paymentMode,
      insuranceCharge: insuranceValue === true ? insurenceAmount : 0,
      customerId: customerInfo.customerId,
      serviceMode: "forward",
    };

    params.pickupAddress = pickupAddress;
    if (
      customerInfo.paymentMode === "prepaid" &&
      customerInfo.walletAmount <
        (customerInfo.minimumBalance
          ? Number(customerInfo.minimumBalance)
          : 250)
    ) {
      displayToast();
    } else {
      dispatch({ type: "SINGLE_SHIPMENT_BOOKING", postData: params });
    }
  }

  useEffect(() => {
    const provider = ServiceProviderList.find(
      (provider) => provider.value === ServiceProvider
    );
    if (provider?.service !== undefined && provider?.service !== null) {
      setServiceTypeOption(provider.service);
    }
  }, [ServiceProvider]);
  return (
    <Box color="blackAlpha.800">
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={[1, 2]} spacing={2}>
          <PaymentModeFormControl
            errors={errors}
            setPaymentMode={setPaymentMode}
            paymentMode={paymentMode}
          />
          {paymentMode === "COD" && (
            <CodAmountFormControl register={register} errors={errors} />
          )}
          <ServiceProviderFormControl
            errors={errors}
            register={register}
            serviceProvider={ServiceProvider}
            setServiceProvider={setServiceProvider}
            ServiceProviderList={ServiceProviderList}
          />
          <ServiceTypeFormControl
            register={register}
            errors={errors}
            setServiceTypeValue={setServiceTypeValue}
            serviceTypeValue={serviceTypeValue}
            ServiceTypeOption={ServiceTypeOption}
          />
          <PickupPointFormControl
            register={register}
            errors={errors}
            setPickupCode={setPickupCode}
            pickupLocations={pickupLocations}
          />
        </SimpleGrid>

        <FormTag tagText={"Destination Details"} />
        <SimpleGrid columns={[1, 2]} spacing={2}>
          <ContactNameFormControl register={register} errors={errors} />
          <ContactMobileFormControl register={register} errors={errors} />
          <AlternateMobileFormControl register={register} errors={errors} />
          <EmailFormControl register={register} errors={errors} />
          <AddressTypeFormControl register={register} errors={errors} />
          <Address1FormControl register={register} errors={errors} />
          <Address2FormControl register={register} errors={errors} />
          <PincodeFormControl
            register={register}
            errors={errors}
            setInputValue={setInputValue}
          />
          <LandmarkFormControl register={register} errors={errors} />
          <CityFormControl
            register={register}
            errors={errors}
            isPincodeDataLoading={isPincodeDataLoading}
          />
          <StateFormControl
            register={register}
            errors={errors}
            isPincodeDataLoading={isPincodeDataLoading}
          />
        </SimpleGrid>
        <FormTag tagText={"Package Information"} />
        <SimpleGrid columns={[1, 2]} spacing={2}>
          <EwayBillFormControl register={register} errors={errors} />
          <SkuNumberFormControl register={register} errors={errors} />
          <OrderNumberFormControl register={register} errors={errors} />
          <ProductNameFormControl register={register} errors={errors} />
          <ProductTypeFormControl
            register={register}
            errors={errors}
            ProductType={ProductType}
          />
          <QuantityFormControl register={register} errors={errors} />
          <WeightFormControl register={register} errors={errors} />
          <LengthFormControl
            register={register}
            errors={errors}
            setLength={setLength}
          />
          <HeightFormControl
            register={register}
            errors={errors}
            setHeight={setHeight}
          />
          <WidthFormControl
            register={register}
            errors={errors}
            setWidth={setWidth}
          />
          <InvoiceFormControl
            register={register}
            errors={errors}
            onInvoiceChange={onInvoiceChange}
          />
        </SimpleGrid>

        {length && width && height && (
          <Box>
            Volumetric Weight :{(length * width * height) / 5000}kg or{" "}
            {(length * width * height) / 5}gram
          </Box>
        )}
        <br />
        <hr />
        <br />
        <Stack spacing={2}>
          <Text fontWeight={700}>
            Do You want to take Shipment Lost/Damage Insurance ( 1% of Invoice
            value {insurenceAmount > 0 ? `= Rs. ${insurenceAmount} ` : ""})
          </Text>
          <Checkbox
            onChange={(e) => setInsuranceValue(e.target.checked)}
            border={"1px gray"}
          >
            Add Insurance Charge ( <InsurancePolicy /> )
          </Checkbox>

          {errorMsg ? <Text color={"red"}>{errorMsg}</Text> : null}
          <Button
            w={100}
            isDisabled={!deliveryCoverage}
            colorScheme="blue"
            isLoading={isBookingDone}
            type="submit"
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default BookingForm;
