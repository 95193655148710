import { createSlice } from "@reduxjs/toolkit";
export const TransactionDetailsSlice = createSlice({
  name: "TransactionDetailsSlice",
  initialState: {
    isTransactionDetailsLoading: false,
    remittancePaidList: [],
  },
  reducers: {
    setTransactionDetailsLoading: (state, action) => {
      state.isTransactionDetailsLoading = action.payload;
    },
    setRemittancePaidList: (state, action) => {
      state.remittancePaidList = action.payload;
    },
  },
});
export const { setTransactionDetailsLoading, setRemittancePaidList } =
  TransactionDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getTransactionDetailsLoading = (state) =>
  state.TransactionDetailsSlice.isTransactionDetailsLoading;
export const getRemittancePaidList = (state) =>
  state.TransactionDetailsSlice.remittancePaidList;
///we can also write thunks by hand , which may contain both sync and async logic

export default TransactionDetailsSlice.reducer;
