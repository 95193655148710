import React from "react";
import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import { FaSpinner } from "react-icons/fa";
const FallbackRoute = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"80vh"}
      w={"80vw"}
      mx={"auto"}
    >
      <>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <FaSpinner
          style={{
            position: "absolute",
            fontSize: "2em",
          }}
        />
      </>
    </Box>
  );
};
export default FallbackRoute;
