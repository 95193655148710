import React from "react";
import {
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Text,
  Input,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

export const CustomFormControl = ({
  controlId,
  label,
  placeholder,
  register,
  errors,
  options,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Select
        id={controlId}
        placeholder={placeholder}
        {...register(controlId, {
          required: "This is required",
        })}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export const CustomPincodeFormControl = ({
  controlId,
  label,
  placeholder,
  register,
  errors,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}{" "}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id={controlId}
        placeholder={placeholder}
        {...register(controlId, {
          required: "This is required",
          pattern: {
            value: /^[1-9][0-9]{5}$/,
            message: "Wrong pincode",
          },
        })}
      />
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export const CustomDeliveryPincodeFormControl = ({
  controlId,
  label,
  placeholder,
  register,
  errors,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}{" "}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id={controlId}
        placeholder={placeholder}
        {...register(controlId, {
          required: "This is required",
          pattern: {
            value: /^[1-9][0-9]{5}$/,
            message: "Wrong pincode",
          },
        })}
      />
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export const PaymentModeControl = ({ controlId, label, control, errors }) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        name={controlId}
        control={control}
        defaultValue=""
        rules={{ required: "This is required" }}
        render={({ field }) => (
          <Select
            id={controlId}
            placeholder={`Select ${label}`}
            onChange={(e) => {
              field.onChange(e);
            }}
            {...field}
          >
            <option value="Prepaid">Prepaid</option>
            <option value="COD">COD</option>
          </Select>
        )}
      />
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};
