import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Image,
  Text,
} from "@chakra-ui/react";
import "./Manifest.scss";
const BookedManifiestSample1 = ({ data }) => {
  return (
    <div className="pagebreak_manifest">
      <TableContainer className="manifiest-table-container_manifest">
        <Table
          variant="simple"
          colorScheme="blackAlpha"
          size="sm"
          color={"black"}
        >
          <Thead color={"black"}>
            <Tr>
              <Th>
                <Image objectFit="cover" src="/quixgo.jpg" alt="Quixgo" />
              </Th>
            </Tr>
            <Tr>
              <Th style={{ textAlign: "center" }} colSpan={2}>
                AWb Number
              </Th>
              <Th colSpan={2} style={{ textAlign: "center" }}>
                Booking Date
              </Th>
              <Th style={{ textAlign: "center" }}>FE Name</Th>
              <Th style={{ textAlign: "center" }}>FE Mob Num</Th>
              <Th style={{ textAlign: "center" }}>Date</Th>
              <Th style={{ textAlign: "center" }}>Fe Sign</Th>
            </Tr>
          </Thead>
          <Tbody color={"black"}>
            {data.map((shipment) => {
              const [bookingDateDate, bookingDateTime] =
                shipment.bookingDate.split(" ");

              return (
                <Tr key={shipment.awbNumber}>
                  <Td colSpan={2} style={{ textAlign: "center" }}>
                    <Text color={"black"}>{shipment.awbNumber}</Text>
                    <Text color={"black"}> {shipment.shipmentPartner}</Text>
                  </Td>
                  <Td colSpan={2} style={{ textAlign: "center" }}>
                    <Text color={"black"}>{bookingDateDate}</Text>
                    <Text color={"black"}>{bookingDateTime}</Text>
                  </Td>
                  <Td style={{ textAlign: "center" }}></Td>
                  <Td style={{ textAlign: "center" }}></Td>
                  <Td style={{ textAlign: "center" }}></Td>
                  <Td style={{ textAlign: "center" }}></Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BookedManifiestSample1;
