import React from "react";
import {
  Avatar,
  HStack,
  Text,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Flex,
  MenuDivider,
  useMediaQuery,
} from "@chakra-ui/react";
import { COLOR } from "../../utils/constant";
import { useDispatch } from "react-redux";
import { setLoggedUserDetails } from "../../redux/slices/UserDetailsSlice";
import { useNavigate } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
const UserMenu = ({ loggedUser }) => {
  const [isLessThan768] = useMediaQuery("(max-width: 769px)");
  const dispatch = useDispatch();
  const fullName = `${loggedUser.firstName} ${loggedUser.lastName}`;
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    dispatch(setLoggedUserDetails(""));
    navigate("/login");
    dispatch({ type: "USER_LOGGED_OUT" });
  };
  return (
    <HStack spacing={{ base: "0", md: "6" }}>
      <Flex alignItems={"center"}>
        <Menu>
          <MenuButton
            py={2}
            transition="all 0.3s"
            _focus={{ boxShadow: "none" }}
          >
            <HStack alignItems="center" color="white" gap={2}>
              <Avatar size={"sm"} name={fullName} />
              {!isLessThan768 && (
                <>
                  <Text fontSize="md" color="white" whiteSpace={"nowrap"}>
                    {fullName}
                  </Text>
                  <FiChevronDown />
                </>
              )}
            </HStack>
          </MenuButton>
          <MenuList bg={COLOR.blue} borderColor={COLOR.blue}>
            <MenuItem
              w={"95%"}
              mx={"auto"}
              rounded={"md"}
              _hover={{ bg: "gray.500" }}
              bg="#274a69"
              color="white"
              onClick={() => handleLogout()}
            >
              Sign out
            </MenuItem>
            <MenuDivider />
            <MenuItem
              w={"95%"}
              mx={"auto"}
              rounded={"md"}
              _hover={{ bg: "gray.500" }}
              bg="#274a69"
              color="white"
            >
              <Text color="white">Customer ID : &nbsp;</Text>
              {loggedUser?.customerId}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </HStack>
  );
};

export default UserMenu;
