import React, { useLayoutEffect } from "react";
import {
  Flex,
  Text,
  Tooltip,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";
import {
  BiSkipNext,
  BiSkipPrevious,
  BiCaretLeft,
  BiCaretRight,
} from "react-icons/bi";
import { getFilterFormData } from "../../redux/slices/ComponentFunctionSlice";
import { useSelector } from "react-redux";
const Pagination = ({ paginationProps }) => {
  const { count, getData } = paginationProps;
  const [isLargerThan800] = useMediaQuery("(min-width: 480px)");
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const filterFormData = useSelector(getFilterFormData);
  useLayoutEffect(() => {
    getData(limit, page, filterFormData);
  }, [page, limit, filterFormData]);
  const ChangeLimit = (e) => {
    setLimit(Number(e.target.value));
  };
  const firstPage = () => {
    setPage(1);
  };
  const NextPage = () => {
    setPage((prev) => prev + 1);
  };
  const PrevPage = () => {
    setPage((prev) => prev - 1);
  };
  const LastPage = () => {
    setPage(Math.floor(count / limit) + 1);
  };
  return (
    <Flex gap={5} flexDir={["column", "row"]}>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        {!isLargerThan800 && (
          <Text fontSize={18} fontWeight="bold" flexShrink="0">
            item :{" "}
          </Text>
        )}
        <Select
          mx={2}
          w={"4.5rem"}
          h={"2rem"}
          value={limit}
          onChange={(e) => {
            ChangeLimit(e);
          }}
        >
          {[10, 20, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
        <Text flexShrink="0">
          Out of{" "}
          <Text fontWeight="bold" as="span">
            {count}
          </Text>
        </Text>
      </Box>
      <Box>
        <HStack borderRadius={5}>
          <Tooltip label="First Page">
            <Box>
              <IconButton
                variant="none"
                p={0}
                m={0}
                fontSize="25px"
                onClick={() => firstPage()}
                icon={<BiSkipPrevious />}
                color={"black"}
                isDisabled={page === 1}
              />
            </Box>
          </Tooltip>

          <Tooltip label="Previous Page">
            <Box>
              <IconButton
                variant="none"
                p={0}
                m={0}
                fontSize="25px"
                onClick={() => PrevPage()}
                icon={<BiCaretLeft />}
                color={"black"}
                isDisabled={page === 1}
              />
            </Box>
          </Tooltip>
          <NumberInput
            w={20}
            defaultValue={1}
            min={1}
            max={Math.floor(count / limit) + 1}
            value={page}
            onChange={(value) => {
              setPage(Number(value));
            }}
          >
            <NumberInputField defaultValue={1} textAlign={"center"} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>

          <Tooltip label="Next Page">
            <Box>
              <IconButton
                variant="none"
                p={0}
                m={0}
                fontSize="25px"
                onClick={() => NextPage()}
                icon={<BiCaretRight />}
                color={"black"}
                isDisabled={page === Math.floor(count / limit) + 1}
              />
            </Box>
          </Tooltip>
          <Tooltip label="Last Page">
            <Box>
              <IconButton
                variant="none"
                p={0}
                m={0}
                fontSize="25px"
                onClick={() => LastPage()}
                icon={<BiSkipNext />}
                color={"black"}
                isDisabled={page === Math.floor(count / limit) + 1}
              />
            </Box>
          </Tooltip>
        </HStack>
      </Box>
    </Flex>
  );
};

export default Pagination;
