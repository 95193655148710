import React from "react";
import { useForm } from "react-hook-form";
import { Button, Flex, Grid, GridItem } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { ToggleFilterButton } from "./CustomFilterComponent";
import { setFilterFormData } from "../../redux/slices/ComponentFunctionSlice";
const FilterContainer = ({ mainComponent, styleprops = {} }) => {
  const [showMoreFilterComponent, setShowMoreFilterComponent] =
    React.useState(false);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const cleanupFormData = (data) => {
    const cleanedData = {};
    Object.keys(data)?.forEach((key) => {
      const value = data?.[key];
      if (value !== undefined && value !== null && value !== "") {
        cleanedData[key] = value;
      }
    });
    return cleanedData;
  };

  const onSubmit = async (data) => {
    try {
      const { dateRange, category, term, operator, ...rest } = data;
      const formattedData = {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        [category]: term,
        [`${category}_operator`]: operator,
        ...rest,
      };
      const cleanedData = cleanupFormData(formattedData);
      await dispatch(setFilterFormData(cleanedData));
      reset({
        category: "",
        term: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const onReset = () => {
    dispatch(setFilterFormData({}));
    reset({
      category: "",
      term: "",
    });
  };
  const firstFourComponents = mainComponent?.slice(0, 4) || [];
  const remainingComponents = mainComponent?.slice(4) || [];
  return (
    <Flex
      w={["100%", "100%", "100%", "100%", "auto"]}
      flexDir={"column"}
      gap={2}
      border={"1px solid"}
      borderColor={"blackAlpha.500"}
      p={2}
      rounded={"md"}
      bg={"blackAlpha.50"}
    >
      <Grid
        order={[1, 0]}
        templateColumns={[
          "auto",
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
          "repeat(4, 1fr)",
          "repeat(5, 1fr)",
        ]}
        gap={[2, 2, 0]}
        justifyContent={"center"}
        dir={"rtl"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Button w={220} type="submit" colorScheme="blue">
            Submit
          </Button>
        </form>
        <Button onClick={onReset} w={220} colorScheme="teal">
          Reset
        </Button>
      </Grid>
      <Grid
        justifyContent={"center"}
        alignItems={"center"}
        gap={6}
        {...styleprops}
      >
        {firstFourComponents?.map((item, idx) =>
          item?.IsGridChild ? (
            <GridItem key={idx}>
              <item.component
                errors={errors}
                register={register}
                control={control}
                key={idx}
                setValue={setValue}
                {...item?.props}
              />
            </GridItem>
          ) : (
            <item.component
              errors={errors}
              register={register}
              control={control}
              key={idx}
              setValue={setValue}
              {...item?.props}
            />
          )
        )}
        {showMoreFilterComponent &&
          remainingComponents?.map((item, idx) =>
            item?.IsGridChild ? (
              <GridItem key={idx}>
                <item.component
                  errors={errors}
                  register={register}
                  control={control}
                  key={idx}
                  setValue={setValue}
                  {...item?.props}
                />
              </GridItem>
            ) : (
              <item.component
                errors={errors}
                register={register}
                control={control}
                key={idx}
                setValue={setValue}
                {...item?.props}
              />
            )
          )}

        <GridItem>
          <ToggleFilterButton
            showMoreFilterComponent={showMoreFilterComponent}
            setShowMoreFilterComponent={setShowMoreFilterComponent}
          />
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default FilterContainer;
