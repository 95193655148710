import React from "react";
import {
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  Icon,
  Button,
  FormErrorMessage,
  InputGroup,
  Box,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { IoWarningOutline } from "react-icons/io5";
import { getLoggedUser } from "../../redux/slices/UserDetailsSlice";
import { useDispatch, useSelector } from "react-redux";

import { getCustomerSetting } from "../../redux/slices/CustomerSettingsSlice";

const ShopifyFulfilment = ({ channelName }) => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const FulFilmentSetting = useSelector(getCustomerSetting)?.channelSettings;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const param = {
      [`${channelName}_storeName`]: data.storeName,
      apiAccessToken: data.apiAccessToken,
      apiKey: data.apiKey,
      apiSecretKey: data.apiSecretKey,
    };
    const params = {
      customerId: loggedUser?.customerId,
      channelSettings: param,
    };
    dispatch({ type: "PUT_FULFILMENT_SETTING", postData: params });
    reset();
  };

  return (
    <Box h={"73vh"} pb={10}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          flexDir={"column"}
          gap={3}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Flex gap={5}>
            <Button type="submit" colorScheme="blue">
              Save
            </Button>
          </Flex>
          <Flex
            boxShadow="0px 4px 6px 6px rgba(0, 0, 0, 0.2)"
            w={["90vw", "90vw", "90vw", 800]}
            px={[5]}
            py={2}
            rounded={"md"}
            flexDir={"column"}
            gap={2}
          >
            <Heading as={"h1"} fontSize={24}>
              Store Name
            </Heading>
            <FormControl isInvalid={errors.storeName}>
              <Input
                defaultValue={FulFilmentSetting?.shopify_storeName}
                type="text"
                {...register("storeName", {
                  required: "storeName is required",
                })}
              />
              <FormErrorMessage>{errors?.storeName?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex
            boxShadow="0px 4px 6px 6px rgba(0, 0, 0, 0.2)"
            w={["90vw", "90vw", "90vw", 800]}
            py={[3]}
            px={5}
            rounded={"md"}
            flexDir={"column"}
            gap={2}
          >
            <Heading as={"h1"} fontSize={24}>
              Admin API access token
            </Heading>
            <Text>
              Use your access token to request data from the{" "}
              <Link>Admin API</Link>.
            </Text>
            <Flex
              bg={"orange.50"}
              whiteSpace={"normal"}
              py={2}
              px={4}
              color={"orange.500"}
              gap={1}
            >
              <Icon as={IoWarningOutline} mt={1} />
              <Text color="blackAlpha.700">
                To keep your online accounts token secure, regularly update your
                passwords and enable two-factor authentication.
              </Text>
            </Flex>
            <FormControl isInvalid={errors.apiAccessToken}>
              <FormLabel>Admin API Access Token</FormLabel>
              <InputGroup>
                <Input
                  defaultValue={FulFilmentSetting?.apiAccessToken}
                  type="text"
                  {...register("apiAccessToken", {
                    required: "API Access Token is required",
                  })}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors?.apiAccessToken?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex
            boxShadow="0px 4px 6px 6px rgba(0, 0, 0, 0.2)"
            w={["90vw", "90vw", "90vw", 800]}
            px={[5]}
            py={4}
            rounded={"md"}
            flexDir={"column"}
            gap={2}
            mb={1}
          >
            <Heading as={"h1"} fontSize={24}>
              API key and secret key
            </Heading>
            <FormControl isInvalid={errors.apiKey}>
              <FormLabel>API Key</FormLabel>
              <Input
                defaultValue={FulFilmentSetting?.apiKey}
                type="text"
                {...register("apiKey", {
                  required: "API Key is required",
                })}
              />
              <FormErrorMessage>{errors?.apiKey?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.apiSecretKey}>
              <FormLabel>API Secret Key</FormLabel>
              <Input
                defaultValue={FulFilmentSetting?.apiSecretKey}
                type="text"
                {...register("apiSecretKey", {
                  required: "API Secret Key is required",
                })}
              />
              <FormErrorMessage>
                {errors?.apiSecretKey?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};

export default ShopifyFulfilment;
