import { useMediaQuery, Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import Navbar from "../containers/navbar";
import Sidebar from "../components/sidebar";
import { COLOR } from "../utils/constant";
import { Outlet } from "react-router-dom";
import Whatsapp from "../pages/Whatsapp";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedUser } from "../redux/slices/UserDetailsSlice";

const PrivateLayout = () => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const [isGreterThan768] = useMediaQuery("(min-width: 769px)");
  React.useEffect(() => {
    if (loggedUser?.customerId) {
      const params = {
        id: loggedUser?.customerId,
        shipmentType: "B2C",
      };
      dispatch({ type: "GET_CUSTOMER_INFO", payload: params });
      dispatch({
        type: "GET_CUSTOMER_SETTING",
        customerId: loggedUser?.customerId,
      });
    }
  }, [loggedUser?.customerId]);
  return (
    <Grid
      h={"100vh"}
      maxW={"100vw"}
      templateRows="repeat(12, 1fr)"
      templateColumns="repeat(23, 1fr)"
    >
      <GridItem bg={COLOR.blue} rowSpan={1} colSpan={23}>
        <Navbar />
      </GridItem>
      {isGreterThan768 && (
        <GridItem bg={COLOR.blue} rowSpan={11} colSpan={1}>
          <Sidebar />
        </GridItem>
      )}
      <GridItem
        bg={"whiteAlpha.100"}
        rowSpan={11}
        colSpan={isGreterThan768 ? 22 : 23}
        overflow="auto"
        p={5}
      >
        <Outlet />
        <Whatsapp />
      </GridItem>
    </Grid>
  );
};

export default PrivateLayout;
