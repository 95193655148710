import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  Text,
  FormErrorMessage,
  Select,
  Input,
  Spinner,
  Tag,
  Flex,
} from "@chakra-ui/react";
export const PaymentModeFormControl = ({
  errors,
  setPaymentMode,
  paymentMode,
}) => {
  return (
    <FormControl isInvalid={errors.paymentMode}>
      <FormLabel htmlFor="paymentMode">
        Order Type
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <RadioGroup
        id="paymentMode"
        onChange={(value) => setPaymentMode(value)}
        defaultValue="COD"
        value={paymentMode}
      >
        <Stack spacing={5} direction="row">
          <Radio colorScheme="blue" value="Prepaid">
            Prepaid
          </Radio>
          <Radio colorScheme="blue" value="COD">
            COD
          </Radio>
        </Stack>
      </RadioGroup>
      <FormErrorMessage>
        {errors.paymentMode && errors?.paymentMode?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
export const ServiceProviderFormControl = ({
  errors,
  setServiceProvider,
  serviceProvider,
  ServiceProviderList,
  register,
}) => {
  return (
    <FormControl isInvalid={errors.serviceProvider}>
      <FormLabel htmlFor="serviceProvider">
        Service Provider
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Select
        id="serviceProvider"
        placeholder="Select Service Provider"
        {...register("serviceProvider", {
          required: "This is required",
        })}
        onChange={(e) => setServiceProvider(e.target.value)}
        value={serviceProvider}
      >
        {ServiceProviderList.map((provider, i) => (
          <option key={provider.value} value={provider.value}>
            {provider.name}
          </option>
        ))}
      </Select>
      <FormErrorMessage>
        {errors.serviceProvider && errors?.serviceProvider?.message}
      </FormErrorMessage>
    </FormControl>
  );
};

export const ServiceTypeFormControl = ({
  register,
  errors,
  setServiceTypeValue,
  serviceTypeValue,
  ServiceTypeOption,
}) => {
  return (
    <FormControl isInvalid={errors.serviceType}>
      <FormLabel htmlFor="serviceType">
        Choose Service
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Select
        value={serviceTypeValue}
        id="serviceType"
        placeholder={"Select Service Type"}
        {...register("serviceType", {
          required: "This is required",
        })}
        onChange={(e) => setServiceTypeValue(e.target.value)}
      >
        {ServiceTypeOption?.map((serviceType) => (
          <option key={serviceType.value} value={serviceType.value}>
            {serviceType.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{errors?.serviceType?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const PickupPointFormControl = ({
  register,
  errors,
  setPickupCode,
  pickupLocations,
}) => {
  return (
    <FormControl isInvalid={errors.pickupPoint}>
      <FormLabel htmlFor="pickupPoint">
        Address Pickup Point{" "}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Select
        id="pickupPoint"
        placeholder="Pickup Point"
        {...register("pickupPoint", {
          required: "This is required",
        })}
        onChange={(e) => {
          const selectedOption = e.target.options[e.target.selectedIndex];
          const selectedPincode = selectedOption.getAttribute("pincode");
          setPickupCode(selectedPincode);
        }}
      >
        {pickupLocations?.map((location) => {
          return (
            <option
              pincode={location.pincode} // set the pincode attribute
              key={location.addressId}
              value={location.addressId}
            >{`${location.cpPerson}-${location.address1}-${location.pincode}`}</option>
          );
        })}
      </Select>
      <FormErrorMessage>{errors?.pickupPoint?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const ContactNameFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.name}>
      <FormLabel htmlFor="name">
        Contact Name{" "}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="name"
        placeholder="Name"
        {...register("name", {
          required: "This is required",
          minLength: {
            value: 4,
            message: "Minimum length should be 4",
          },
          maxLength: {
            value: 50,
            message: "Maximum length should be 50",
          },
        })}
      />
      <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const ContactMobileFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.mobileNumber}>
      <FormLabel htmlFor="mobileNumber">
        Contact Mobile
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="mobileNumber"
        placeholder="Mobile Number"
        {...register("mobileNumber", {
          required: "This is required",
          pattern: {
            value: /^[6-9]\d{9}$/,
            message: "Wrong mobile number",
          },
          maxLength: {
            value: 10,
            message: "Maximum length should be 10",
          },
          minLength: {
            value: 10,
            message: "Minimum length should be 10",
          },
        })}
      />
      <FormErrorMessage>{errors?.mobileNumber?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const AlternateMobileFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors?.alternateNumber}>
      <FormLabel htmlFor="alternateNumber">Alternate Mobile</FormLabel>
      <Input
        id="alternateNumber"
        placeholder="Alternate Mobile Number"
        {...register("alternateNumber", {
          pattern: {
            value: /^[6-9]\d{9}$/,
            message: "Wrong mobile number",
          },
          maxLength: {
            value: 10,
            message: "Maximum length should be 10",
          },
          minLength: {
            value: 10,
            message: "Minimum length should be 10",
          },
        })}
      />
      <FormErrorMessage>{errors?.alternateNumber?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const EmailFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors?.email}>
      <FormLabel htmlFor="email">Email Id</FormLabel>
      <Input
        id="email"
        type="email"
        placeholder="abc@xyz.com"
        {...register("email", {
          pattern: {
            value: /[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
            message: "Wrong email id",
          },
        })}
      />
      <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const AddressTypeFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors?.addressType}>
      <FormLabel htmlFor="addressType">Delivery Address Type</FormLabel>
      <Select
        id="addressType"
        placeholder="Select Address Type"
        {...register("addressType", {
          required: "This is required",
        })}
      >
        <option>Home</option>
        <option>Office</option>
      </Select>
      <FormErrorMessage>{errors?.addressType?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const Address1FormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors?.address1}>
      <FormLabel htmlFor="address1">Address1</FormLabel>
      <Input
        id="address1"
        placeholder="Address1"
        {...register("address1", {
          required: "This is required",
          minLength: {
            value: 4,
            message: "Minimum length should be 4",
          },
          maxLength: {
            value: 100,
            message: "Maximum length should be 100",
          },
        })}
      />
      <FormErrorMessage>{errors?.address1?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const Address2FormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors?.address2}>
      <FormLabel htmlFor="address2">Address2</FormLabel>
      <Input
        id="address2"
        placeholder="Address2"
        {...register("address2", {
          minLength: {
            value: 4,
            message: "Minimum length should be 4",
          },
          maxLength: {
            value: 100,
            message: "Maximum length should be 100",
          },
        })}
      />
      <FormErrorMessage>{errors?.address2?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const PincodeFormControl = ({ register, errors, setInputValue }) => {
  return (
    <FormControl
      isInvalid={errors.pincode}
      onChange={(e) => setInputValue(e.target.value)}
    >
      <FormLabel htmlFor="pincode">
        Pincode
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="pincode"
        placeholder="Pincode"
        {...register("pincode", {
          required: "This is required",
          pattern: {
            value: /^[1-9][0-9]{5}$/,
            message: "Wrong pincode",
          },
        })}
      />
      <FormErrorMessage>{errors?.pincode?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const LandmarkFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.landmark}>
      <FormLabel htmlFor="landmark">Landmark</FormLabel>
      <Input
        id="landmark"
        placeholder="Landmark"
        {...register("landmark", {
          minLength: {
            value: 4,
            message: "Minimum length should be 4",
          },
          maxLength: {
            value: 40,
            message: "Maximum length should be 40",
          },
        })}
      />
      <FormErrorMessage>{errors?.landmark?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const CityFormControl = ({ register, errors, isPincodeDataLoading }) => {
  return (
    <FormControl isInvalid={errors.city}>
      <FormLabel htmlFor="city">
        City
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        isDisabled={!!isPincodeDataLoading}
        cursor={isPincodeDataLoading ? "not-allowed" : "auto"}
        _disabled={{ opacity: "0.8" }}
        id="city"
        placeholder="City"
        {...register("city", {
          required: "This is required",
        })}
      />
      {isPincodeDataLoading ? <Spinner color="blue.500" /> : null}
      <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const StateFormControl = ({
  register,
  errors,
  isPincodeDataLoading,
}) => {
  return (
    <FormControl isInvalid={errors.state}>
      <FormLabel htmlFor="state">
        State
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        isDisabled={!!isPincodeDataLoading}
        cursor={isPincodeDataLoading ? "not-allowed" : "auto"}
        _disabled={{ opacity: "0.8" }}
        id="state"
        placeholder="State"
        {...register("state", { required: "This is required" })}
      />
      {isPincodeDataLoading ? <Spinner color="blue.500" /> : null}
      <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const EwayBillFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.ewayBill}>
      <FormLabel htmlFor="ewayBill">E-Way Bill Number</FormLabel>
      <Input
        id="ewayBill"
        placeholder="Eway Bill Number"
        {...register("ewayBill")}
      />
    </FormControl>
  );
};

export const SkuNumberFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.skuNumber}>
      <FormLabel htmlFor="skuNumber">
        SKU Number
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="skuNumber"
        type="text"
        placeholder="Enter SKU Number"
        {...register("skuNumber", {
          required: "This is required",
        })}
      />
      <FormErrorMessage>{errors?.skuNumber?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const OrderNumberFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.orderNumber}>
      <FormLabel htmlFor="orderNumber">
        Order Number
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="orderNumber"
        type="text"
        placeholder="Enter Order Number"
        {...register("orderNumber", {
          required: "This is required",
        })}
      />
      <FormErrorMessage>{errors?.orderNumber?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const ProductNameFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.productName}>
      <FormLabel htmlFor="productName">
        Product Description
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="productName"
        placeholder="Enter Product Name"
        {...register("productName", {
          required: "This is required",
          minLength: {
            value: 2,
            message: "Minimum length should be 2",
          },
        })}
      />
      <FormErrorMessage>{errors?.productName?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const ProductTypeFormControl = ({ register, errors, ProductType }) => {
  return (
    <FormControl isInvalid={errors.productType}>
      <FormLabel htmlFor="productType">
        Product Type
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Select
        id="productType"
        placeholder="Select Product Type"
        {...register("productType", {
          required: "This is required",
        })}
      >
        {ProductType.map((pType) => (
          <option key={pType.value} value={pType.value}>
            {pType.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{errors?.productType?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const WeightFormControl = ({ register, errors, ...props }) => {
  return (
    <FormControl isInvalid={errors.weight}>
      <FormLabel htmlFor="Weight">
        Weight (kg)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        {...props}
        id="weight"
        type="number"
        step="any"
        placeholder="Weight (0.4 kg) = 400gm"
        {...register("weight", {
          required: "This is required",
        })}
      />
      <FormErrorMessage>{errors?.weight?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const QuantityFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.quantity}>
      <FormLabel htmlFor="quantity">
        Quantity
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="quantity"
        placeholder="Enter Quantity"
        {...register("quantity", {
          required: "This is required",
        })}
      />

      <FormErrorMessage>{errors?.quantity?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const LengthFormControl = ({ register, errors, setLength }) => {
  return (
    <FormControl isInvalid={errors.length}>
      <FormLabel htmlFor="length">
        Length (cm)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="length"
        type="number"
        step={"any"}
        placeholder="Enter Length "
        {...register("length", {
          required: "This is required",
        })}
        onChange={(e) => setLength(e.target.value)}
      />

      <FormErrorMessage>{errors?.length?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const WidthFormControl = ({ register, errors, setWidth }) => {
  return (
    <FormControl isInvalid={errors.width}>
      <FormLabel htmlFor="width">
        Width (cm)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="width"
        type="number"
        step={"any"}
        placeholder="Enter Breadth "
        {...register("width", {
          required: "This is required",
        })}
        onChange={(e) => setWidth(e.target.value)}
      />

      <FormErrorMessage>{errors?.width?.message}</FormErrorMessage>
    </FormControl>
  );
};
export const HeightFormControl = ({ register, errors, setHeight }) => {
  return (
    <FormControl isInvalid={errors.height}>
      <FormLabel htmlFor="height">
        Height (cm)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>

      <Input
        id="height"
        type="number"
        step={"any"}
        placeholder="Enter Height "
        {...register("height", {
          required: "This is required",
        })}
        onChange={(e) => setHeight(e.target.value)}
      />

      <FormErrorMessage>{errors?.height?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const InvoiceFormControl = ({ register, errors, onInvoiceChange }) => {
  const handleChange = (e) => {
    if (typeof onInvoiceChange === "function") {
      onInvoiceChange(e.target.value);
    }
  };

  return (
    <FormControl isInvalid={errors.invoice}>
      <FormLabel htmlFor="invoice">
        Invoice Value
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="invoice"
        type="number"
        placeholder="Enter Invoice Value"
        {...register("invoice", {
          required: "This is required",
        })}
        onChange={handleChange}
      />
      <FormErrorMessage>{errors?.invoice?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const CodAmountFormControl = ({ register, errors }) => {
  return (
    <FormControl isInvalid={errors.codAmount}>
      <FormLabel htmlFor="codAmount">
        COD Amount
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="codAmount"
        type="number"
        placeholder="Enter COD Amount"
        {...register("codAmount", {
          required: "This is required",
        })}
      />
      <FormErrorMessage>{errors?.codAmount?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const FormTag = ({ tagText, ...props }) => {
  return (
    <Flex justifyContent={"center"} my={3} {...props}>
      <Tag
        textAlign={"center"}
        colorScheme="blackAlpha"
        px={5}
        py={2}
        letterSpacing={1.5}
        fontSize={16}
      >
        {tagText}
      </Tag>
    </Flex>
  );
};
