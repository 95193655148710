import React from "react";
import { useForm } from "react-hook-form";
import { Button, Box, SimpleGrid } from "@chakra-ui/react";
import { SERVICEMODE } from "../../utils/utilityConstants";
import DimensionInput from "./DimensionInput";
import {
  CodAmountFormControl,
  FormTag,
  InvoiceFormControl,
  WeightFormControl,
} from "../BookShipment/BookingFormField";
import {
  CustomDeliveryPincodeFormControl,
  CustomFormControl,
  CustomPincodeFormControl,
  PaymentModeControl,
} from "./CalculatorField";
const B2BcalculatorForm = ({ close }) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      volume: [{ Qty: "", height: "", length: "", width: "" }],
    },
  });

  function convertToCm(value, unit) {
    if (unit.toLowerCase() === "feet") {
      return value * 30.48 + "cm";
    } else if (unit?.toLowerCase() === "inch") {
      return value * 2.54 + "cm";
    }
    return value + "cm";
  }
  function onSubmit(values) {
    let errorOccurred = false;
    try {
      values?.volume.forEach((entry) => {
        entry.height = convertToCm(parseFloat(entry?.height), values?.unit);
        entry.length = convertToCm(parseFloat(entry?.length), values?.unit);
        entry.width = convertToCm(parseFloat(entry?.width), values?.unit);
      });
    } catch (error) {
      errorOccurred = true;
      console.error("Error during form submission:", error);
    } finally {
      if (errorOccurred) {
        console.log("Form submission failed");
      } else {
        console.log("Form submitted with values:", values);
      }
    }
  }

  return (
    <Box color="blackAlpha.800">
      <>
        <SimpleGrid spacingX={4} spacingY={2} columns={[1, 2]}>
          <CustomFormControl
            controlId="serviceType"
            label="Service Type"
            placeholder="Select Service Type"
            register={register}
            errors={errors}
            options={Object.keys(SERVICEMODE).map((mode) => ({
              value: mode,
              label: SERVICEMODE[mode],
            }))}
          />
          <CustomPincodeFormControl
            controlId="pickUpPincode"
            label="Pickup Pincode"
            placeholder="PickUp Pincode"
            register={register}
            errors={errors}
          />
          <CustomDeliveryPincodeFormControl
            controlId="deliveryPincode"
            label="Delivery Pincode"
            placeholder="Delivery Pincode"
            register={register}
            errors={errors}
          />
          <PaymentModeControl
            controlId="paymentMode"
            label="Payment Mode"
            control={control}
            errors={errors}
          />

          {watch("paymentMode") === "COD" && (
            <CodAmountFormControl register={register} errors={errors} />
          )}

          <InvoiceFormControl register={register} errors={errors} />
        </SimpleGrid>

        <FormTag tagText={"Dimensions"} my={2} />
        <WeightFormControl
          register={register}
          errors={errors}
          width={295}
          mb={2}
        />
        <DimensionInput register={register} control={control} errors={errors} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Button
            mt={4}
            colorScheme="blue"
            isLoading={isSubmitting}
            type="submit"
          >
            Submit
          </Button>
        </form>
      </>
    </Box>
  );
};
export default B2BcalculatorForm;
