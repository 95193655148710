import React from "react";
import { InputGroup, InputLeftElement, Icon, Input } from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";

const NavbarSearch = ({ placeholder, onChange, ...props }) => {
  return (
    <InputGroup {...props}>
      <InputLeftElement pointerEvents="none">
        <Icon as={AiOutlineSearch}></Icon>
      </InputLeftElement>
      <Input
        type="tel"
        placeholder={placeholder}
        bg="white"
        onChange={onChange}
        {...props}
      />
    </InputGroup>
  );
};

export default NavbarSearch;
