import { Flex, Text, Box, Heading, SimpleGrid } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./layout.scss";
import { getLoggedUser } from "../redux/slices/UserDetailsSlice";
import { setLayoutType } from "../redux/slices/ComponentFunctionSlice";
import {
  getCustomerType,
  getIsCustomerTypeLoading,
} from "../redux/slices/CustomerDetailsSlice";
import QuixgoText from "../components/shared/QuixgoText";
import { toast } from "react-toastify";
import LayoutCard from "./LayoutCard";
import ContentLoader from "../components/Loader/ContentLoader";

const Layout = () => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const Customer = useSelector(getCustomerType);
  const isCustomerLoading = useSelector(getIsCustomerTypeLoading);
  const navigate = useNavigate();
  const {
    isB2bActive,
    isB2bAccount,
    isActive,
    isB2cAccount,
    isB2cActive,
    isDeleted,
  } = Customer ?? {};
  const isB2bAllowed = isB2bAccount && isB2bActive && isActive && !isDeleted;
  const isB2cAllowed = isB2cAccount && isB2cActive && isActive && !isDeleted;
  const handleB2bCardCLick = () => {
    if (isB2bAllowed) {
      dispatch(setLayoutType("b2b"));
      navigate("/b2b/dashboard");
    } else {
      toast.error("B2B service is not active , Contact to Admin", {
        closeOnClick: true,
        draggable: true,
      });
    }
  };
  const handleB2cCardCLick = () => {
    if (isB2cAllowed) {
      dispatch(setLayoutType("b2c"));
      navigate("/b2c/dashboard");
    } else {
      toast.error("B2C service is not active , Contact to Admin", {
        closeOnClick: true,
        draggable: true,
      });
    }
  };
  return isCustomerLoading ? (
    <ContentLoader />
  ) : (
    <Box
      backgroundImage={"/web_poster.webp"}
      backgroundSize={"contain"}
      backgroundRepeat={"repeat"}
    >
      <Flex height={["full", "full", "full", `calc(100vh - 62px)`]}>
        <Flex
          justifyContent={"space-around"}
          alignItems={"center"}
          height={["full", "full", "full", `calc(100vh - 156px)`]}
          rounded={"16px"}
          pos={"relative"}
          mt={10}
          p={[0, 5]}
          className="glassUI"
          w={"95vw"}
          mx={"auto"}
          flexDir={"column"}
          gap={[0, 5]}
        >
          <>
            <Heading color={"whiteAlpha.900"} fontSize={"xl"} mx={"auto"}>
              Welcome {`${loggedUser.firstName} ${loggedUser.lastName}`}
            </Heading>
            <SimpleGrid columns={[1, 1, 1, 2]} spacingY={5} columnGap={24}>
              <Flex
                flexDir={"column"}
                w={[240, "80vw", 540, "80%", 540]}
                h={[280, "full", "full", 320]}
                py={5}
                whiteSpace={"break-spaces"}
              >
                <QuixgoText fontSize={["lg", "5xl"]} fontWeight={"bold"} />
                <Text color={"whiteAlpha.700"} fontSize={[16]}>
                  Welcome to QUIXGO, your gateway to unparalleled service in the
                  Indian eCommerce realm. We guarantee the best commercial rates
                  from top courier partners, automated reports, and stress-free
                  deliveries with our NDR feature. Enjoy COD and Prepaid
                  services across 25000+ Pincodes. Your success, our priority.
                </Text>
              </Flex>
              <Flex
                gap={5}
                alignItems={"center"}
                flexDir={["column", "row"]}
                justifyContent={"center"}
              >
                <LayoutCard
                  heading={"B2B"}
                  text={
                    "Are you tired of handling multiple courier companies for your business shipments? B2B courieraggregation might be the solution you need."
                  }
                  onClick={handleB2bCardCLick}
                  cursor={isB2bAllowed ? "pointer" : "not-allowed"}
                />
                <LayoutCard
                  heading={"B2C"}
                  text={
                    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus illum neque quo rem nostrum suscipit ipsa quas fuga numquam. Aliquid repellendus asperiores nisi distinctio fugiat non maxime ducimus rerum vero!"
                  }
                  onClick={handleB2cCardCLick}
                  cursor={isB2cAllowed ? "pointer" : "not-allowed"}
                />
              </Flex>
            </SimpleGrid>
          </>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Layout;
