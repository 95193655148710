import React from "react";
import { Img, Text } from "@chakra-ui/react";
export const StepCount = ({ index }) => {
  const pageColor = "#274a69";
  return (
    <Text
      bg={pageColor}
      color="white"
      px={"12px"}
      py={1}
      fontWeight={"bold"}
      fontSize={"lg"}
      pos={"absolute"}
      top={"-3"}
      left={"-3"}
      rounded={"full"}
      zIndex={"-1"}
    >
      {index}
    </Text>
  );
};
export const StepImg = ({ ImgSrc, AltText, ImgCaption }) => (
  <>
    <Text textTransform={"capitalize"} fontSize={18}>
      {ImgCaption}
    </Text>
    <Img
      borderRadius={"md"}
      ml={[0, 8]}
      w={220}
      src={ImgSrc}
      alt={AltText}
      objectFit="contain"
      loading="lazy"
      _hover={{ transform: "scale(2)" }}
      zIndex={1}
    />
  </>
);
