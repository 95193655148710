import { combineReducers } from "redux";
import UserDetailsSlice from "./UserDetailsSlice";
import CustomerDetailsSlice from "./CustomerDetailsSlice";
import BookingDetailsSlice from "./BookingDetailsSlice";
import PaymentDetailsSlice from "./PaymentDetailsSlice";
import DashboardDetailsSlice from "./DashboardDetailsSlice";
import DiscrepancyDetailsSlice from "./DiscrepancyDetailsSlice";
import TransactionDetailsSlice from "./TransactionDetailsSlice";
import CustomerSettingsSlice from "./CustomerSettingsSlice";
import ComponentFunctionSlice from "./ComponentFunctionSlice";
const appReducer = combineReducers({
  /* your app’s top-level reducers */
  UserDetailsSlice,
  CustomerDetailsSlice,
  BookingDetailsSlice,
  PaymentDetailsSlice,
  DashboardDetailsSlice,
  DiscrepancyDetailsSlice,
  TransactionDetailsSlice,
  CustomerSettingsSlice,
  ComponentFunctionSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
