import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCustomerType } from "../redux/slices/CustomerDetailsSlice";
import { useEffect } from "react";

const useKycRoutes = () => {
  const customer = useSelector(getCustomerType);
  const isKyc = customer?.isDocumentUploaded;
  const navigate = useNavigate();
  useEffect(() => {
    const navigateToCorrectRoute = () => {
      navigate(isKyc ? "/" : "/kyc");
    };
    navigateToCorrectRoute();
  }, [isKyc, navigate]);

  return () => {};
};

export default useKycRoutes;
