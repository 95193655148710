import { httpCall, GET_DISCREPANCY_DETAILS_URL } from "../../utils/api";
import { AUTH_USER_CG_TOKEN_KEY } from "../../utils/constant";
import { call, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  setDiscrepancy,
  setDiscrepancyLoading,
} from "../slices/DiscrepancyDetailsSlice";

export function* weightDiscrepancy(payload) {
  const { postData } = payload;
  setDiscrepancyLoading(true);
  let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  let weightUrl = `${process.env.REACT_APP_BASEURL}${GET_DISCREPANCY_DETAILS_URL}`;

  try {
    const discrepancyDetails = yield call(httpCall, {
      url: weightUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
      },
      data: postData,
    });

    if (discrepancyDetails.data && discrepancyDetails.success) {
      yield put(setDiscrepancy(discrepancyDetails.data));
      setDiscrepancyLoading(false);
    } else {
      toast.error("Something went wrong");
      setDiscrepancyLoading(false);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchDiscrepancy() {
  yield takeLatest("GET_ALL_DISCREPANCY_DETAILS", weightDiscrepancy);
}

export default function* DiscrepancyDetailsSaga() {
  yield all([watchDiscrepancy()]);
}
