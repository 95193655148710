import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Icon,
  useDisclosure,
  Heading,
} from "@chakra-ui/react";
import CalculateForm from "./CalculatorForm";
import B2BcalculatorForm from "./B2BcalculatorForm";
import { BsFillCalculatorFill } from "react-icons/bs";
import { getLayoutType } from "../../redux/slices/ComponentFunctionSlice";
import { useSelector } from "react-redux";

const Calculatedrawer = () => {
  const layOutType = useSelector(getLayoutType);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Icon
        as={BsFillCalculatorFill}
        w={[6, 7]}
        h={[6, 7]}
        color="white"
        _hover={{ cursor: "pointer" }}
        mr={1}
        onClick={onOpen}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size={"lg"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader bg="lightgrey">
            <Heading as="h1" size="md" color={"blackAlpha.700"}>
              Calculator
            </Heading>
          </DrawerHeader>
          <DrawerBody>
            {layOutType === "b2b" ? (
              <B2BcalculatorForm close={onClose} />
            ) : (
              <CalculateForm close={onClose} />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default Calculatedrawer;
