import React from "react";
import {
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  getCustomerInfo,
  getIsCustomerInfoLoading,
} from "../../../redux/slices/CustomerDetailsSlice";
import Loader from "../../../components/Loader/TableLoader";
const NormalCustomerDetailsForm = ({ onChange, next, isEdit = true }) => {
  const customerInfo = useSelector(getCustomerInfo);
  const isLoading = useSelector(getIsCustomerInfoLoading);

  const onSubmit = async (values) => {
    const customerInfoParams = {
      cpName: values.cpName,
      cpMobile: values.cpMobile,
      email: values.email,
      address1: values.address1,
      address2: values.address2,
      district: values.district,
      city: values.city,
      state: values.state,
      pin: values.pin,
      website: values.website,
    };
    onChange("normalCustomerDetails", customerInfoParams);
    next();
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  return (
    <>
      <Heading as="h2" size={"lg"} color="blackAlpha.800" mb={4}>
        {" "}
        Tell us about yourself.
      </Heading>
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleGrid columns={[1, 2, 3]} spacing={2}>
            <FormControl isInvalid={errors.cpName} isReadOnly={!isEdit}>
              <FormLabel htmlFor="cpName">
                Name
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                id="cpName"
                readOnly={!isEdit}
                placeholder="Contact Person Name"
                type="text"
                defaultValue={customerInfo.cpName}
                {...register("cpName", {
                  required: "This is required",
                  pattern: {
                    value: /^[a-zA-Z]{2,40}( [a-zA-Z1-9]{2,40})+$/,
                    message: "Please enter a valid name(first_name last_name) ",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.cpName && errors.cpName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.cpMobile} isReadOnly={!isEdit}>
              <FormLabel htmlFor="cpMobile">
                Mobile No.
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                readOnly={!isEdit}
                id="cpMobile"
                placeholder=" Contact Person Mobile No."
                defaultValue={customerInfo.cpMobile}
                type="text"
                {...register("cpMobile", {
                  required: "This is required",
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: "Wrong mobile number",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.cpMobile && errors.cpMobile.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.email} isReadOnly={!isEdit}>
              <FormLabel htmlFor="email">
                Email ID
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>
              <Input
                readOnly={!isEdit}
                id="email"
                placeholder="example123@gmail.com"
                defaultValue={customerInfo.email}
                type="text"
                {...register("email", {
                  required: "This is required",
                  pattern: {
                    value: /[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                    message: "Wrong email id",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <Heading as="h2" size={"lg"} color="blackAlpha.800" my={4}>
            Address
          </Heading>
          <SimpleGrid columns={[1, 2, 3]} spacing={2}>
            <FormControl isInvalid={errors.address1} isReadOnly={!isEdit}>
              <FormLabel>
                Address Line 1
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Input
                readOnly={!isEdit}
                id="address1"
                placeholder="Flat No./Building No., Road/Street, etc."
                defaultValue={customerInfo.address1}
                type="text"
                {...register("address1", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                {" "}
                {errors.address1 && errors.address1.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.address2} isReadOnly={!isEdit}>
              <FormLabel>
                Address Line 2
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Input
                readOnly={!isEdit}
                id="address1"
                placeholder="Area/City near by(location) etc."
                defaultValue={customerInfo.address2}
                type="text"
                {...register("address2", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                {" "}
                {errors.address2 && errors.address2.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.city} isReadOnly={!isEdit}>
              <FormLabel>
                City
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Input
                readOnly={!isEdit}
                id="city"
                placeholder="City"
                defaultValue={customerInfo.city}
                type="text"
                {...register("city", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                {" "}
                {errors.city && errors.city.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.district} isReadOnly={!isEdit}>
              <FormLabel>
                District
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Input
                readOnly={!isEdit}
                id="district"
                placeholder="District"
                defaultValue={customerInfo.district}
                type="text"
                {...register("district", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                {" "}
                {errors.district && errors.district.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.state} isReadOnly={!isEdit}>
              <FormLabel>
                State
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Input
                readOnly={!isEdit}
                id="state"
                placeholder="State"
                defaultValue={customerInfo.state}
                type="text"
                {...register("state", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                {" "}
                {errors.state && errors.state.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.pin} isReadOnly={!isEdit}>
              <FormLabel>
                Pin
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Input
                readOnly={!isEdit}
                id="pin"
                placeholder="Pincode"
                defaultValue={customerInfo.pin}
                type="text"
                {...register("pin", {
                  required: "This is required",
                  pattern: {
                    value: /^([1-9]{1}[0-9]{5})$/,
                    message: "Wrong pincode",
                  },
                })}
              />
              <FormErrorMessage>
                {" "}
                {errors.pin && errors.pin.message}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <Button
            position={"absolute"}
            bottom={"21px"}
            right={"20px"}
            colorScheme="blue"
            isLoading={isLoading || isSubmitting}
            type="submit"
          >
            Next
          </Button>
        </form>
      )}
    </>
  );
};

export default NormalCustomerDetailsForm;
