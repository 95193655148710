import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import QuixgoText from "./QuixgoText";
import { COLOR } from "../../utils/constant";
import { getLoggedUser } from "../../redux/slices/UserDetailsSlice";
import useKycRoutes from "../../hooks/useIsKyc";
import UserMenu from "../../containers/navbar/UserMenu";

const MainNavbar = ({ ...props }) => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const navigateIfkycNotVerified = useKycRoutes();
  React.useEffect(() => {
    loggedUser?.customerId &&
      dispatch({
        type: "GET_CUSTOMER_TYPE",
        payload: { id: loggedUser.customerId },
      });
  }, [loggedUser]);

  React.useEffect(() => {
    navigateIfkycNotVerified();
  }, []);

  return (
    <Grid templateRows="repeat(12, 1fr)" h={"100vh"}>
      <GridItem
        display={"flex"}
        rowSpan={1}
        alignItems="center"
        justifyContent={"space-between"}
        bg={COLOR.blue}
        px={8}
        {...props}
      >
        <QuixgoText fontSize={["lg", "3xl"]} />
        <UserMenu loggedUser={loggedUser} />
      </GridItem>
      <GridItem rowSpan={11} overflowY="auto">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

export default MainNavbar;
