import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  setLoggedUserDetails,
  setUserInfoLoader,
  setUserEmailId,
  setUserVerifyLoader,
  setUserVerificationError,
  setUserVarifyStatus,
  setPasswordChanges,
  setEmailLoader,
} from "../slices/UserDetailsSlice";
import {
  httpCall,
  LOGIN_URL,
  GET_SUBSCRIBER_INFO_URL,
  SEND_OTP_URL,
  RESEND_OTP_URL,
  VARIFY_OTP_URL,
  UPDATE_PASSWORD_URL,
} from "../../utils/api";
import {
  AUTH_USER_CG_TOKEN_KEY,
  COURIER_SERVICE_LOGIN,
} from "../../utils/constant";
import qs from "qs";
import { toast } from "react-toastify";
export function* LoginUser(params) {
  try {
    const { postData } = params;
    yield put(setUserInfoLoader(true));
    let data = qs.stringify(postData);
    const loginUrl = `${process.env.REACT_APP_BASEURL}${LOGIN_URL}`;
    const logedUserValue = yield call(httpCall, {
      url: loginUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    });
    if (logedUserValue?.success) {
      const { token } = logedUserValue.data;
      localStorage.setItem(AUTH_USER_CG_TOKEN_KEY, token);
      if (postData.remember) {
        localStorage.setItem(
          AUTH_USER_CG_TOKEN_KEY,
          `${token}_=${logedUserValue.data.annotation_id}`
        );
      }
      yield put(setUserInfoLoader(false));
      localStorage.setItem(AUTH_USER_CG_TOKEN_KEY, token);
      localStorage.setItem(
        COURIER_SERVICE_LOGIN,
        JSON.stringify(logedUserValue.data)
      );
      yield put(setLoggedUserDetails(logedUserValue.data));
      toast.success("User Logged In Successfully");
    } else {
      // setUserEmailId()
      yield put(setUserInfoLoader(false));
      toast.error(logedUserValue.err.data);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getLoggedUserInfo(params) {
  const { postData } = params;
  yield put(setUserInfoLoader(true));
  const userInfoUrl = `${process.env.REACT_APP_BASEURL}${GET_SUBSCRIBER_INFO_URL}`;
  const postParams = postData.split("_=");
  const data = qs.stringify({
    id: postParams[1],
  });
  try {
    const logedUserValue = yield call(httpCall, {
      url: userInfoUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: postParams[0],
      },
      data,
    });
    if (logedUserValue?.success) {
      logedUserValue.data.token = postParams[0];
      yield put(setUserInfoLoader(false));
      yield put(setLoggedUserDetails(logedUserValue.data));
      localStorage.setItem(AUTH_USER_CG_TOKEN_KEY, postParams[0]);
      localStorage.setItem(
        COURIER_SERVICE_LOGIN,
        JSON.stringify(logedUserValue.data)
      );
    } else {
      toast.error("Try Loging");
      yield put(setUserInfoLoader(false));
    }
  } catch (err) {
    console.log(err);
    toast.error("Something went wrong!");
  }
}

export function* sendOTP(params) {
  const { postData } = params;
  const userInfoUrl = `${process.env.REACT_APP_BASEURL}${SEND_OTP_URL}`;
  const data = qs.stringify(postData);
  try {
    const logedUserValue = yield call(httpCall, {
      url: userInfoUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data,
    });
    if (logedUserValue?.success) {
      yield put(setUserEmailId(logedUserValue.data));
      toast.success("Email Sent Successfully");
    } else {
      toast.error(logedUserValue.err.data);
      yield put(setUserInfoLoader(false));
    }
  } catch (err) {
    console.log(err);
    toast.error("Something went wrong!");
  }
}
export function* ResendOtp(params) {
  try {
    const { postData } = params;
    console.log(postData);
    let data = qs.stringify(postData);
    const otpUrl = `${process.env.REACT_APP_BASEURL}${RESEND_OTP_URL}`;
    const otpEmailValues = yield call(httpCall, {
      url: otpUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    });
    if (otpEmailValues?.success) {
      yield put(setEmailLoader(false));
      toast.success("Email Sent Successfully");
    }
  } catch (err) {
    console.log(err);
  }
}

export function* VerifyUserOtp(params) {
  try {
    const { postData } = params;
    console.log(postData);
    let data = qs.stringify(postData);
    const varifyUserUrl = `${process.env.REACT_APP_BASEURL}${VARIFY_OTP_URL}`;
    const otpVarifyValues = yield call(httpCall, {
      url: varifyUserUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    });
    if (otpVarifyValues?.success && !otpVarifyValues.data.error) {
      // yield put(setEmailLoader(false));
      yield put(setUserVerifyLoader(false));
      yield put(setUserVerificationError(false));
      yield put(setUserVarifyStatus(true));
    } else {
      yield put(setUserVerificationError(true));
      yield put(setUserVerifyLoader(false));
      toast.error(otpVarifyValues.data.error);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* updateUserPassword(params) {
  try {
    // console.log(params);
    const { postData } = params;
    var data = qs.stringify(postData);
    const varifyUserUrl = `${process.env.REACT_APP_BASEURL}${UPDATE_PASSWORD_URL}`;
    const passwordChnageValues = yield call(httpCall, {
      url: varifyUserUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    });
    if (
      passwordChnageValues &&
      passwordChnageValues.success &&
      !passwordChnageValues.data.error
    ) {
      yield put(setPasswordChanges(true));
      toast.success("Password Changed Successfully");
    } else {
      yield put(setPasswordChanges(false));
      toast.error(passwordChnageValues.err.data.error);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchUserDetailsSaga() {
  // yield takeLatest('REGISTER_USER',RegisterUser);
  yield takeLatest("LOGIN_USER", LoginUser);
  yield takeLatest("User_INFO", getLoggedUserInfo);
  yield takeLatest("SEND_OTP", sendOTP);
  yield takeLatest("RESEND_OTP", ResendOtp);
  yield takeLatest("VERIFY_USER_OTP", VerifyUserOtp);
  yield takeLatest("UPDATE_PASSWORD", updateUserPassword);
}

export default function* UserDetailsSaga() {
  yield all([watchUserDetailsSaga()]);
}
