import { Flex, Heading, Text } from "@chakra-ui/react";
const LayoutCard = ({ heading, text, onClick, ...props }) => {
  return (
    <Flex
      w={[240, 250]}
      h={[280, 320]}
      bg={"#274a69"}
      rounded={"xl"}
      color={"white"}
      justifyContent={"center"}
      flexDir={"column"}
      p={[5]}
      gap={2}
      _hover={{ backgroundColor: "black" }}
      onClick={onClick}
      {...props}
    >
      <Heading color={"whiteAlpha.900"} size="xl" fontWeight={"bold"}>
        {heading}
      </Heading>
      <Text color={"whiteAlpha.700"} lineHeight={1.5}>
        {text}
      </Text>
    </Flex>
  );
};

export default LayoutCard;
