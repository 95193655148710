import React, { useEffect, useState } from "react";
import {
  Text,
  SimpleGrid,
  FormControl,
  FormLabel,
  Button,
  Icon,
  FormErrorMessage,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import FileUpload from "../../../components/FileUpload";

import { FiFile } from "react-icons/fi";
import Selfie from "./Selfie";
import { CustomerType } from "../../../utils/utilityConstants";
import {
  getCustomerInfo,
  getCustomerInfoFormData,
  getIsCustomerInfoLoading,
} from "../../../redux/slices/CustomerDetailsSlice";
const KycDocumentForm = ({ data, onClose }) => {
  const dispatch = useDispatch();
  const customerInfo = useSelector(getCustomerInfo);
  const customerInfoFormData = useSelector(getCustomerInfoFormData);
  const IsCustomerInfoLoading = useSelector(getIsCustomerInfoLoading);
  const [image, setImage] = useState("");
  const [selfieError, setSelfieError] = useState(false);
  useEffect(() => {
    if (image) {
      setSelfieError(false);
    } else {
      setSelfieError(true);
    }
  }, [image]);

  const validateSelfie = () => {
    if (!image) {
      setSelfieError(true);
      return true;
    }
  };
  const onSubmit = async (values, e) => {
    e.preventDefault();
    if (validateSelfie()) {
      return;
    }
    const addressDetails = customerInfoFormData?.companyDetails
      ? customerInfoFormData?.companyDetails
      : customerInfoFormData?.normalCustomerDetails;
    const customerAddress = {
      customerId: customerInfo.customerId,
      companyName: addressDetails.companyName,
      companyContact: addressDetails.companyContact,
      addressType: "self",
      cpPerson: addressDetails.cpName,
      address1: addressDetails.address1,
      address2: `${addressDetails.address2}-${addressDetails.city}`,
      city: addressDetails.district,
      state: addressDetails.state,
      pincode: addressDetails.pin,
      landmark: "",
      cpMobile: addressDetails.cpMobile,
      email: addressDetails.email,
    };
    const docInfoParams = {
      customerId: customerInfo.customerId,
      selfieDoc: image,
      aadharFront: values.aadharFront[0],
      aadharBack: values.aadharBack[0],
    };
    const params = {
      address: customerAddress,
    };
    if (customerInfoFormData?.customerType === CustomerType.BUSINESS) {
      const { shipmentType } = customerInfoFormData?.media;
      const companyInfoParams = {
        customerId: customerInfo.customerId,
        customerType: customerInfoFormData?.customerType,
        customerNeed: customerInfoFormData?.job,
        avgShipment: customerInfoFormData?.count,
        sellMedia: customerInfoFormData?.media.sellPoint.join(","),
        productCategory: customerInfoFormData?.media.productCategory.join(","),
        isB2bAccount: shipmentType?.includes("isB2bAccount") ? 1 : 0,
        isB2cAccount: shipmentType?.includes("isB2cAccount") ? 1 : 0,
        isHyperLocal: shipmentType?.includes("isHyperLocal") ? 1 : 0,
        isInternationalShipment: shipmentType?.includes(
          "isInternationalShipment"
        )
          ? 1
          : 0,
        website: customerInfoFormData?.companyDetails.website,
        brandName: customerInfoFormData?.companyDetails.brandName,
      };
      params.companyInfo = companyInfoParams;
      docInfoParams.gstDoc = values.gst[0];
      docInfoParams.logoDoc = values.logo[0];
    }

    params.docInfo = docInfoParams;
    dispatch({ type: "ADD_KYC_INFO", postData: params });
    onClose();
  };
  const validateFiles = (value) => {
    if (value.length < 1) {
      return "It is required";
    }
    // for (const file of Array.from(value)) {
    //   const fskb = file.size;
    //   const MAX_FILE_SIZE = 61440;
    //   if (fskb > MAX_FILE_SIZE) {
    //     return "Max file size 60kb";
    //   }
    // }
    return true;
  };

  // const validateLogoGst = (value) => {
  //   for (const file of Array.from(value)) {
  //     const fskb = file.size;
  //     const MAX_FILE_SIZE = 61440;
  //     if (fskb > MAX_FILE_SIZE) {
  //       return "Max file size 60kb";
  //     }
  //   }
  //   return true;
  // };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  return (
    <VStack spacing={8}>
      <Text color={"#3182ce"} fontSize="md">
        Almost done! Please upload your document to complete KYC
      </Text>

      <FormControl isInvalid={selfieError}>
        <FormLabel textAlign={"center"} fontSize={[12, 14]}>
          (Make sure your photos are not blurry,enough light and not wearing a
          mask, eye glasses )
        </FormLabel>
        <Selfie onChange={setImage} />
        <FormErrorMessage mx={"auto"}>
          <Text textAlign={"center"} w={"100%"}>
            {selfieError && "Picture is required"}
          </Text>
        </FormErrorMessage>
      </FormControl>

      <SimpleGrid mx={"auto"} columns={[1, 2]} spacing={4}>
        <FormControl isInvalid={!!errors.aadharFront} isRequired>
          <FormLabel m={0}>{"Aadhar Front"}</FormLabel>
          <FileUpload
            accept={"image/*"}
            multiple={false}
            register={register("aadharFront", { validate: validateFiles })}
            w={220}
          >
            {/* <Button leftIcon={<Icon as={FiFile} />}>Upload</Button> */}
          </FileUpload>

          <FormErrorMessage>
            {errors.aadharFront && errors?.aadharFront.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.aadharBack} isRequired>
          <FormLabel m={0}>Aadhar Back</FormLabel>
          <FileUpload
            accept={"image/*"}
            multiple={false}
            register={register("aadharBack", { validate: validateFiles })}
            w={220}
          />
          <FormErrorMessage>
            {errors.aadharBack && errors?.aadharBack.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel m={0}>GST (optional) </FormLabel>
          <FileUpload
            accept={"image/*"}
            multiple={false}
            register={register("gst")}
            w={220}
          />
        </FormControl>
        {data.customerType === CustomerType.BUSINESS && (
          <FormControl isInvalid={!!errors.logo}>
            <FormLabel m={0}>{"Logo"}</FormLabel>
            <FileUpload
              accept={"image/*"}
              multiple={false}
              register={register("logo")}
              w={220}
            />
            {/* <Button w={220} leftIcon={<Icon as={FiFile} />}>
                Upload
              </Button> */}

            <FormErrorMessage>
              {errors.logo && errors?.logo.message}
            </FormErrorMessage>
          </FormControl>
        )}
      </SimpleGrid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button
          position={"absolute"}
          bottom={"21px"}
          right={"20px"}
          colorScheme="blue"
          isLoading={IsCustomerInfoLoading}
          type="submit"
        >
          Save
        </Button>
      </form>
    </VStack>
  );
};

export default KycDocumentForm;
