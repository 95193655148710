import { useRef, useState } from "react";
import {
  Button,
  Flex,
  Icon,
  InputGroup,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { MdDelete, MdImage } from "react-icons/md";

const FileUpload = ({ register, accept, multiple, ...props }) => {
  const [file, setFile] = useState();
  const inputRef = useRef(null);
  const { ref, ...rest } = register;

  const handleClick = () => inputRef.current?.click();
  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleDelete = () => {
    console.log("delete");
    inputRef.current.value = null;
    setFile(null);
    ref(inputRef.current);
  };
  return (
    <Flex color="white" gap={4} flexDir={["column", "column", "row"]}>
      <InputGroup
        onClick={handleClick}
        onChange={(e) => handleChange(e)}
        w={220}
      >
        <input
          type={"file"}
          multiple={multiple || false}
          hidden
          {...rest}
          accept={accept}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
        />

        <Button
          leftIcon={<MdImage />}
          variant="outline"
          colorScheme="blue"
          {...props}
        >
          Upload
        </Button>
      </InputGroup>
      {file && (
        <>
          {" "}
          <Button
            leftIcon={<MdDelete />}
            variant="outline"
            colorScheme="red"
            w={220}
            onClick={handleDelete}
          >
            {file.name}
          </Button>
          {/* <Tag
            borderRadius="lg"
            variant="outline"
            colorScheme="blue"
            w={220}
            h={10}
            overflowX={"auto"}
          >
            <TagLabel textAlign={"center"} fontSize={"20px"}>
              {file.name}
            </TagLabel>
            <Icon
              cursor={"pointer"}
              as={MdDelete}
              w={6}
              h={6}
              color="red.500"
              onClick={handleDelete}
            />
          </Tag> */}
        </>
      )}
    </Flex>
  );
};

export default FileUpload;
