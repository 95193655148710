import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  setPaymentAccessKey,
  setIsPaymentLoader,
  setAllTransactionList,
  setPaymentDetailsLoading,
} from "../slices/PaymentDetailsSlice";
import {
  httpCall,
  SAVE_TRANSACTION_URL,
  GET_PAYMENT_ACCESS_KEY_URL,
  GETALL_TRANSACTIONS_URL,
} from "../../utils/api";
import { getCustomerInfo } from "./CustomerDetailsSaga";
import { AUTH_USER_CG_TOKEN_KEY } from "../../utils/constant";
import { toast } from "react-toastify";
export function* getPaymentToken(params) {
  let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { payload } = params;
  const getPaymentTokenUrl = `${process.env.REACT_APP_BASEURL}${GET_PAYMENT_ACCESS_KEY_URL}`;
  try {
    const paymentTokenData = yield call(httpCall, {
      url: getPaymentTokenUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: payload,
    });
    if (paymentTokenData?.success) {
      yield put(setPaymentAccessKey(paymentTokenData.data));
    } else {
      toast.error("Something went wrong");
    }
  } catch (err) {
    console.log(err);
  }
}
export function* saveTransactionDetails(params) {
  let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { payload } = params;
  const setPaymentTokenUrl = `${process.env.REACT_APP_BASEURL}${SAVE_TRANSACTION_URL}`;
  try {
    const paymentInitialData = yield call(httpCall, {
      url: setPaymentTokenUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: payload,
    });
    if (paymentInitialData?.success) {
      const params = {
        id: payload.customerId,
      };
      yield getCustomerInfo({
        type: "GET_CUSTOMER_INFO",
        payload: params,
      });
      yield put(setPaymentAccessKey(null));
      toast.success(" Wallet Recharged Successfully", {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error("Something went wrong");
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getAllTransactionDetails(payload) {
  let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { postData } = payload;
  const getAllTransactionUrl = `${process.env.REACT_APP_BASEURL}${GETALL_TRANSACTIONS_URL}`;
  yield put(setPaymentDetailsLoading(true));
  try {
    const transactionDetails = yield call(httpCall, {
      url: getAllTransactionUrl,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: postData,
    });
    if (transactionDetails?.success) {
      yield put(setAllTransactionList(transactionDetails.data));
      yield put(setPaymentDetailsLoading(false));
      yield put(setIsPaymentLoader(false));
    } else {
      toast.error("Something went wrong");
      yield put(setPaymentDetailsLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* watchPaymentDetailsSaga() {
  yield takeLatest("GET_PAYMENT_TOKEN", getPaymentToken);
  yield takeLatest("SET_TRANSACTION_DETAILS", saveTransactionDetails);
  yield takeLatest("GETALL_TRANSACTIONS", getAllTransactionDetails);
}

export default function* PaymentDetailsSaga() {
  yield all([watchPaymentDetailsSaga()]);
}
