import React from 'react';
import { Flex, Stack, Image } from '@chakra-ui/react';
import { useSelector,useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import {
  getPasswordChanges,
  getUserVerifyStatus,
} from '../redux/slices/UserDetailsSlice';
import PasswordResetForm from '../containers/Auth/ResetPasswordForm';
import OtpForm from '../containers/Auth/OtpInput';

const ResetPasswordPage = () => {
  const dispatch =useDispatch();
  const isUserVarified = useSelector(getUserVerifyStatus);
  const isPasswordChanged = useSelector(getPasswordChanges);
  const navigate = useNavigate();
  if (isPasswordChanged) {
    navigate('/login');
    dispatch({type:'USER_LOGGED_OUT'})
  }
  return (
    <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>

      <Image
            w={'150px'}
            objectFit="cover"
            src="/quixgo.jpg"
            alt="Quixgo"
            mb={'120px'}
            />
        {isUserVarified ? <PasswordResetForm /> : <OtpForm />}
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={'Login Image'}
          // objectFit={'cover'}
          // src="/quixgo.jpg"
          src="/web_poster.webp"
          />
      </Flex>
          </Stack>
  );
};

export default ResetPasswordPage;
