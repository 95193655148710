import React from "react";
import { MdEmail, MdLocationCity, MdPhone } from "react-icons/md";
import { Box, Text, Flex, Icon, Tooltip, Divider } from "@chakra-ui/react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import { RiErrorWarningFill } from "react-icons/ri";
import { MdOutlineDoneAll } from "react-icons/md";
import { useSelector } from "react-redux";
import { getLayoutType } from "../../redux/slices/ComponentFunctionSlice";
const CustomerAddress = ({ row }) => {
  return (
    <Box>
      <Text color="blue">
        Name:{" "}
        <Text as="span" color="black">
          {row?.name || "Not available"}
        </Text>
      </Text>

      <Box color="blue">
        Address:{" "}
        <Text as="span" color="black">
          {row?.add1}, {row?.add2},
          <Text as="span" color="black">
            {row?.lmk}
          </Text>
          <Text as="span" color="black">
            {row?.state}: {row?.pincode}
          </Text>
        </Text>
      </Box>

      <Flex alignItems="center">
        <Icon as={MdLocationCity} boxSize={6} color="blue" />
        <Text fontSize="1rem" color="black">
          : {row?.address_city || "Not available"}
        </Text>
      </Flex>

      <Flex alignItems="center">
        <Icon as={MdEmail} boxSize={6} color="blue" />
        <Text fontSize="1rem" color="black">
          : {row?.email || "Not available"}
        </Text>
      </Flex>

      <Flex alignItems="center">
        <Icon as={MdPhone} boxSize={6} color="blue" />
        <Text fontSize="1rem" color="black">
          : {row?.mobile || "Not available"}
        </Text>
      </Flex>
    </Box>
  );
};

export const Deliv_To = ({ row }) => {
  const { deliveryAddress } = row;
  const deliveryAddressDetails = deliveryAddress
    ? JSON.parse(deliveryAddress)
    : undefined;
  return (
    <Tooltip
      border={"2px solid #800080"}
      rounded={"md"}
      bg={"white"}
      label={<CustomerAddress row={deliveryAddressDetails} />}
    >
      <Box>
        <Text color="blackAlpha.800">{deliveryAddressDetails?.name}</Text>
        <Text>
          {deliveryAddressDetails?.pincode || deliveryAddressDetails?.pin}
          <Icon as={RiErrorWarningFill} color={"green.500"} pt={"4px"} />
        </Text>
      </Box>
    </Tooltip>
  );
};
const currentStatusColor = (currentStatus) => {
  const statusColorMap = {
    Delivered: "green",
    Booked: "blue",
    "return In Transit": "orange",
  };
  return statusColorMap[currentStatus] || "red";
};
export const CurrentStatus = ({ row }) => {
  return (
    <>
      {row?.currentStatus === "ERROR" ? (
        <>
          <Tooltip
            border={"2px solid #800080"}
            rounded={"md"}
            bg={"white"}
            label={<Text>{JSON.stringify(row?.otherInfo_remark)}</Text>}
          >
            <Text
              textTransform={"capitalize"}
              color={currentStatusColor(row?.currentStatus)}
            >
              {row.currentStatus}
              <Icon
                as={RiErrorWarningFill}
                color={"green.500"}
                ml={2}
                boxSize={3}
              />
            </Text>
          </Tooltip>
        </>
      ) : (
        <Text
          textTransform={"capitalize"}
          color={currentStatusColor(row?.currentStatus)}
        >
          {row?.currentStatus}
        </Text>
      )}
    </>
  );
};
export const AWBStatus = ({ row }) => {
  const location = useLocation();
  return (
    <Link
      as={NavLink}
      to={`/b2c/status/${row.awbNumber}`}
      params={{ awbNumber: row.awbNumber }}
      color="green.500"
      fontSize={13}
      textDecoration="underline"
      state={{ from: location.pathname }}
    >
      {row.awbNumber}
    </Link>
  );
};
export const TrackingDetails = ({ row }) => {
  return (
    <Box textAlign="center">
      <Text fontWeight={"bold"} color="black" textTransform={"capitalize"}>
        {row.shipmentPartner}
        <Text
          px={1}
          rounded={"md"}
          fontSize={"10px"}
          as={"span"}
          bg={row.serviceTypes == "express" ? "whatsapp.200" : "skyblue"}
          fontWeight={"medium"}
          fontFamily={"cursive"}
          color="black"
        >
          {row?.serviceTypes?.slice(0, 3)}
        </Text>{" "}
      </Text>

      <AWBStatus row={row} />
    </Box>
  );
};
export const BookingChannelDetails = ({ row }) => {
  return (
    <>
      <Text px={1} rounded={"md"} as={"span"} color="black">
        {row?.bookingChannel}
      </Text>
      <Tooltip rounded={"md"} label={"Order ID"}>
        <Text
          px={1}
          rounded={"md"}
          fontSize={"15px"}
          fontWeight={"medium"}
          color="blackAlpha.700"
        >
          {row?.orderId}
        </Text>
      </Tooltip>
    </>
  );
};

export const Payment = ({ row }) => {
  return (
    <Tooltip
      border={"2px solid #800080"}
      rounded={"md"}
      bg={"white"}
      label={<PaymentDetails row={row} />}
    >
      <Box>
        <Text color={row.paymentMode === "Prepaid" ? "#0089F1" : "#4B0082"}>
          {row.paymentMode}
          {row?.isInsured === true ? (
            <Text
              ml={1}
              rounded={"md"}
              fontSize={"10px"}
              as={"span"}
              bg={"whatsapp.200"}
              fontWeight={"medium"}
              fontFamily={"cursive"}
              color="black"
            >
              {" "}
              {"Ins"}
            </Text>
          ) : (
            ""
          )}

          {row?.isSettled === true && row?.paymentMode === "COD" ? (
            <Text
              ml={1}
              as={"span"}
              fontWeight={900}
              color={"green"}
              style={{ display: "inline-block", fontSize: "16px" }}
            >
              <Icon as={MdOutlineDoneAll} color={"black"} />
            </Text>
          ) : (
            ""
          )}
        </Text>
        <Text fontSize={13} color={"green"}>
          <BiRupee style={{ display: "inline-block", fontSize: "14px" }} />
          {row.finalCharge}{" "}
          <Icon as={RiErrorWarningFill} color={"green.500"} pt={"3px"} />
        </Text>
      </Box>
    </Tooltip>
  );
};
export const PaymentDetails = ({ row }) => {
  const formatAmount = (label, amount) => (
    <Text textAlign="right">
      {label} - {amount} INR
    </Text>
  );

  return (
    <>
      {formatAmount("Shipment Charge", row?.shipmentCharge)}
      {formatAmount("Insurance Charge", row?.insuranceCharge)}
      {row?.codCharges > 0 && formatAmount("Cod Charge", row?.codCharges)}
      {formatAmount("Discount", row?.discount)}
      <Divider borderColor="red" />
      <Text textAlign="right" color="green">
        Total: {row?.finalCharge} INR
      </Text>
    </>
  );
};
export const PackgaeDetails = ({ row }) => {
  const navigate = useNavigate();
  const { productDetails } = row;
  const productDetailsDetails = productDetails
    ? JSON.parse(productDetails)
    : undefined;
  return (
    <>
      <Text color={row?.isDiscrepancy === true ? "red" : "blackAlpha.700"}>
        {Number(productDetailsDetails?.height).toFixed(2)} x{" "}
        {Number(productDetailsDetails?.length).toFixed(2)} x{" "}
        {Number(productDetailsDetails?.width).toFixed(2)} CM
      </Text>
      <Text color={row?.isDiscrepancy === true ? "red" : "blackAlpha.900"}>
        {Number(productDetailsDetails?.weight).toFixed(2)} KG
        {row?.isDiscrepancy === true ? (
          <Link
            ml={2}
            rounded={"md"}
            fontSize={"16px"}
            as={"span"}
            fontWeight={"medium"}
            fontFamily={"san serif"}
            color="blue"
            textDecor={"underline"}
            onClick={() => navigate("/weightmanagement")}
          >
            {"Disc"}
          </Link>
        ) : (
          ""
        )}
      </Text>
    </>
  );
};
export const BookingDate = ({ row }) => {
  return <Text>{row?.bookingDate}</Text>;
};
export const updatedAtDate = ({ row }) => {
  return <Text>{row?.updatedAt}</Text>;
};
