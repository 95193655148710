import React, { useState } from "react";
import {
  Button,
  Textarea,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

import { useDispatch } from "react-redux";

const CancelButton = ({ row, onClose, isOpen }) => {
  const dispatch = useDispatch();
  const [msg, setMsg] = useState();
  const cancelRef = React.useRef();

  const handleConfirmationClick = () => {
    const params = {
      msg: msg,
      awbNumber: row.awbNumber,
      customerId: row.customerId,
    };
    dispatch({ type: "CANCEL_SHIPMENT", postData: params });

    onClose();
  };
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancel Shipment {row.awbNumber}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
              {row?.currentStatus?.toLowerCase() !== "delivered" && (
                <Textarea
                  placeholder="Please Enter Reason for cancellation"
                  onChange={(e) => setMsg(e.target.value)}
                />
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleConfirmationClick}
                ml={3}
                disabled={
                  row?.currentStatus?.toLowerCase() !== "delivered" && !msg
                }
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default CancelButton;
