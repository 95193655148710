import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  httpCall,
  SAVE_DEFAULT_ADDRESS_URL,
  SAVE_COURIER_SETTINGS_URL,
  GET_COURIER_SETTINGS_URL,
  SAVE_PACKAGE_VOLUME_URL,
  SAVE_MANIFEST_SETTING_URL,
  SAVE_FULFILMENT_SETTING_URL,
  SAVE_DEFAULT_BANKACCOUNT_URL,
} from "../../utils/api";
import { AUTH_USER_CG_TOKEN_KEY } from "../../utils/constant";
import { toast } from "react-toastify";
import {
  setCustomerSetting,
  setIsCustomerSettingLoading,
} from "../slices/CustomerSettingsSlice";

export function* getCustomerSetting(params) {
  yield put(setIsCustomerSettingLoading(true));
  let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { customerId } = params;
  const getCustomerSettingUrl = `${process.env.REACT_APP_BASEURL}${GET_COURIER_SETTINGS_URL}/${customerId}`;
  try {
    const customerSetting = yield call(httpCall, {
      url: getCustomerSettingUrl,
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
      },
    });
    if (customerSetting?.success) {
      yield put(setCustomerSetting(customerSetting.data));
      yield put(setIsCustomerSettingLoading(false));
    } else {
      toast.error("Something went wrong");
      yield put(setIsCustomerSettingLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setIsCustomerSettingLoading(false));
  }
}
export function* putCourierSettings(params) {
  try {
    let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const putCourierUrl = `${process.env.REACT_APP_BASEURL}${SAVE_COURIER_SETTINGS_URL}`;
    const { postData } = params;
    const refreshDataParams = JSON.parse(postData);
    const courierUpdateResponse = yield call(httpCall, {
      url: putCourierUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: postData,
    });
    if (courierUpdateResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        customerId: refreshDataParams?.customerId,
      });
    } else {
      toast.error(courierUpdateResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}
export function* putPackageVolume(params) {
  try {
    let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getPackageVolumeUrl = `${process.env.REACT_APP_BASEURL}${SAVE_PACKAGE_VOLUME_URL}`;
    const { postData } = params;
    const PackageVolumeResponse = yield call(httpCall, {
      url: getPackageVolumeUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: postData,
    });
    if (PackageVolumeResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        postData: { id: postData?.customerId },
      });
    } else {
      toast.error(PackageVolumeResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}
export function* putManifestSetting(params) {
  try {
    let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getPackageVolumeUrl = `${process.env.REACT_APP_BASEURL}${SAVE_MANIFEST_SETTING_URL}`;
    const { postData } = params;
    const ManifestSettingResponse = yield call(httpCall, {
      url: getPackageVolumeUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: postData,
    });
    if (ManifestSettingResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });

      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        postData: { id: postData?.customerId },
      });
    } else {
      toast.error(ManifestSettingResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}
export function* putFulfilmentSetting(params) {
  try {
    let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getUrl = `${process.env.REACT_APP_BASEURL}${SAVE_FULFILMENT_SETTING_URL}`;
    const { postData } = params;
    const FulfilmentSettingResponse = yield call(httpCall, {
      url: getUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: postData,
    });
    if (FulfilmentSettingResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });

      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        postData: { id: postData?.customerId },
      });
    } else {
      toast.error(FulfilmentSettingResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}
export function* putDefaultAddress(params) {
  try {
    let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getAllIdUrl = `${process.env.REACT_APP_BASEURL}${SAVE_DEFAULT_ADDRESS_URL}`;
    const { postData } = params;
    const DefaultAddressUpdateResponse = yield call(httpCall, {
      url: getAllIdUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: postData,
    });
    if (DefaultAddressUpdateResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        postData: { id: postData?.customerId },
      });
    } else {
      toast.error(DefaultAddressUpdateResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}
export function* putDefaultBank(params) {
  try {
    let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getBankUrl = `${process.env.REACT_APP_BASEURL}${SAVE_DEFAULT_BANKACCOUNT_URL}`;
    const { postData } = params;
    const DefaultBankUpdateResponse = yield call(httpCall, {
      url: getBankUrl,
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: postData,
    });
    if (DefaultBankUpdateResponse?.success) {
      toast.success("Information Updated Successfully", {
        closeOnClick: true,
        draggable: true,
      });
      yield getCustomerSetting({
        type: "GET_CUSTOMER_SETTING",
        postData: { id: postData?.customerId },
      });
    } else {
      toast.error(DefaultBankUpdateResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log("error", err);
  }
}
export function* watchCustomerSettingsSaga() {
  yield takeLatest("PUT_DEFAULT_ADDRESS", putDefaultAddress);
  yield takeLatest("PUT_DEFAULT_COURIER", putCourierSettings);
  yield takeLatest("PUT_DEFAULT_BANK", putDefaultBank);
  yield takeLatest("PUT_PACKAGE_VOLUME", putPackageVolume);
  yield takeLatest("PUT_MANIFEST_SETTING", putManifestSetting);
  yield takeLatest("PUT_FULFILMENT_SETTING", putFulfilmentSetting);
  yield takeLatest("GET_CUSTOMER_SETTING", getCustomerSetting);
}

export default function* CustomerSettingsSaga() {
  yield all([watchCustomerSettingsSaga()]);
}
