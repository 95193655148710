import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, SimpleGrid, Box } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { SERVICEMODE } from "../../utils/utilityConstants";
import { getLoggedUser } from "../../redux/slices/UserDetailsSlice";
import { useNavigate } from "react-router-dom";
import { formatValue } from "../../utils/utilsFunction";
import {
  WeightFormControl,
  CodAmountFormControl,
  LengthFormControl,
  WidthFormControl,
  HeightFormControl,
} from "../BookShipment/BookingFormField";
import {
  CustomDeliveryPincodeFormControl,
  CustomFormControl,
  CustomPincodeFormControl,
  PaymentModeControl,
} from "./CalculatorField";
const Calculatepage = ({ close }) => {
  const loggedUser = useSelector(getLoggedUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [length, setLength] = useState();
  function onSubmit(values) {
    const params = {
      ...values,
      weight: formatValue(values?.weight),
      height: formatValue(values?.height),
      length: formatValue(values?.length),
      width: formatValue(values?.width),
      customerId: loggedUser.customerId,
    };
    dispatch({ type: "CALCULATE_PRICE", payload: params });
    navigate("/b2c/calculatePrice");
    // close();
  }

  return (
    <Box color="blackAlpha.800">
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={[1, 2]} spacing={2}>
          <CustomFormControl
            controlId="serviceType"
            label="Service Type"
            placeholder="Select Service Type"
            register={register}
            errors={errors}
            options={Object.keys(SERVICEMODE).map((mode) => ({
              value: mode,
              label: SERVICEMODE[mode],
            }))}
          />
          <CustomPincodeFormControl
            controlId="pickUpPincode"
            label="Pickup Pincode"
            placeholder="PickUp Pincode"
            register={register}
            errors={errors}
          />
          <CustomDeliveryPincodeFormControl
            controlId="deliveryPincode"
            label="Delivery Pincode"
            placeholder="Delivery Pincode"
            register={register}
            errors={errors}
          />
          <PaymentModeControl
            controlId="paymentMode"
            label="Payment Mode"
            control={control}
            errors={errors}
          />
          {watch("paymentMode") === "COD" && (
            <CodAmountFormControl register={register} errors={errors} />
          )}
          <WeightFormControl register={register} errors={errors} />

          <LengthFormControl
            register={register}
            errors={errors}
            setLength={setLength}
          />
          <HeightFormControl
            register={register}
            errors={errors}
            setHeight={setHeight}
          />
          <WidthFormControl
            register={register}
            errors={errors}
            setWidth={setWidth}
          />
        </SimpleGrid>
        <Button
          mt={4}
          colorScheme="blue"
          isLoading={isSubmitting}
          type="submit"
        >
          Submit
        </Button>
        <Box>
          Volumetric Weight : {""}
          {(length && width && height) != null
            ? (length * width * height) / 5000
            : ""}
          kg or{" "}
          {(length && width && height) != null
            ? (length * width * height) / 5
            : ""}
          gram
        </Box>
      </form>
    </Box>
  );
};
export default Calculatepage;
