import React from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import orderCreation from "../../../src/assets/images/orderCreation.jpeg";
import webHook_Api_version from "../../../src/assets/images/webHook_Api_version.jpeg";
import SetupUrlButton from "../SetupUrlButton";
import { StepCount, StepImg } from "../ChannelComponent";
const ShopifySetupPage = () => {
  return (
    <Box h={["73vh","full"]}>
      <Heading
        as={"h1"}
        size={"lg"}
        mb={4}
        textAlign={"center"}
        color={"blackAlpha.800"}
      >
        Follow These steps for using WebHooks.
      </Heading>

      <Flex gap={[8, 5]} flexWrap={"wrap"} justifyContent={"center"}>
        <Flex
          boxShadow="0px 4px 6px 6px rgba(0, 0, 0, 0.2)"
          flexDir={"column"}
          p={4}
          rounded={"md"}
          gap={2}
          pos={"relative"}
        >
          {[
            "login to shopify",
            "Go to settings",
            "Go to notification",
            "Scroll down to bottom find web hook",
            "click create webhook",
          ].map((text) => (
            <Text key={text} textTransform={"capitalize"} fontSize={18}>
              👉 {text}
            </Text>
          ))}
          <StepCount index={1} />
        </Flex>
        <Flex
          flexDir={"column"}
          p={4}
          boxShadow="0px 4px 6px 6px rgba(0, 0, 0, 0.2)"
          rounded={"md"}
          gap={1}
          pos={"relative"}
        >
          <StepImg
            ImgSrc={orderCreation}
            ImgCaption={"👉 select order creation in Event"}
            AltText={"select order creation in Event"}
          />
          <Text textTransform={"capitalize"} fontSize={18}>
            👉 select json in format
          </Text>
          <Text
            textTransform={"capitalize"}
            fontSize={18}
            whiteSpace={"normal"}
          >
            👉 Now paste URL from Quixgo in URL field
          </Text>
          <StepCount index={2} />
        </Flex>
        <Flex
          flexDir={"column"}
          p={4}
          boxShadow="0px 4px 6px 6px rgba(0, 0, 0, 0.2)"
          rounded={"md"}
          gap={1}
          pos={"relative"}
        >
          <StepImg
            ImgSrc={webHook_Api_version}
            ImgCaption={"👉 Select webhook Api version to latest"}
            AltText={"Select webhook Api version to latest "}
          />

          <Text textTransform={"capitalize"} fontSize={18}>
            👉 click on
            <Text as={"span"} ml={2} color={"green.500"} fontWeight={"bold"}>
              Save
            </Text>
          </Text>
          <StepCount index={3} />
        </Flex>
        <Box mb={5}>
          <SetupUrlButton channelName={"shopify"} />
        </Box>
      </Flex>
    </Box>
  );
};

export default ShopifySetupPage;
