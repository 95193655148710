/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { COURIER_SERVICE_LOGIN } from "../utils/constant";
import { setLoggedUserDetails } from "../redux/slices/UserDetailsSlice";

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    const isLoggedIn = JSON.parse(localStorage.getItem(COURIER_SERVICE_LOGIN));
    const token = isLoggedIn ? isLoggedIn.token : null;

    if (isLoggedIn && token) {
      dispatch(setLoggedUserDetails(isLoggedIn));
      navigate(from, { replace: true });
    }
  }, []);

  return (
    <main className="App">
      <Outlet />
    </main>
  );
};

export default Layout;
