import { createSlice } from "@reduxjs/toolkit";

const DiscrepancySlice = createSlice({
  name: "Discrepancy",
  initialState: {
    discrepancy: [],
    discrepancyLoading: false,
  },

  reducers: {
    setDiscrepancy: (state, action) => {
      state.discrepancy = action.payload;
    },
    setDiscrepancyLoading: (state, action) => {
      state.discrepancyLoading = action.payload;
    },
  },
});

export const { setDiscrepancy, setDiscrepancyLoading } =
  DiscrepancySlice.actions;

export const getDiscrepancy = (state) => {
  return state.DiscrepancyDetailsSlice.discrepancy;
};

export const getDiscrepancyLoading = (state) =>
  state.DiscrepancyDetailsSlice.discrepancyLoading;

export default DiscrepancySlice.reducer;
